import { Box, BoxProps, useColorModeValue, useToken } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useNeutralPalette } from '../useNeutralPalette';

export interface PaperProps extends BoxProps {}

export const Paper: FC<PaperProps> = (props) => {
  const neutralpalette = useNeutralPalette();
  const backgroundcolor = useColorModeValue(`white`, `black`);
  const shadowcolor = useToken(`colors`, neutralpalette[200]);
  const boxshadow = `0 0 35px -5px ${shadowcolor}`;

  return (
    <Box
      backgroundColor={backgroundcolor}
      borderRadius="2xl"
      boxShadow={boxshadow}
      color={neutralpalette[900]}
      {...props}
    />
  );
};
