import { HStack } from '@chakra-ui/layout';
import { Select } from '@chakra-ui/react';
import { useAllProfessorDetail } from '@hasura/hooks/useAllProfessorDetail';
import { ProfessorDetail } from '@typings/professor/ProfessorDetail';
import React, { FC, useEffect } from 'react';
import { FnUnary, pass } from 'tiinvo';
import { useStr } from 'use-tiinvo';

export interface ProfessorTypePickerProps {
  onChange?: FnUnary<string, any>;
  value?: string;
}

export const ProfessorTypePicker: FC<ProfessorTypePickerProps> = ({
  onChange = pass,
  value = ``,
}) => {
  const detail = useAllProfessorDetail();
  const disciplinary = useStr(``);

  const data = detail.data?.professor_detail ?? [];
  const disciplinaryarea = Object.entries(
    data.reduce(
      (a, b) => ({ ...a, [b.disciplinary_area]: b }),
      {} as Record<string, ProfessorDetail>,
    ),
  );
  const filtered =
    disciplinary.value === ``
      ? data
      : data.filter((a) => a.disciplinary_area === disciplinary.value);

  useEffect(() => {
    const found = data.find((a) => a.id === value);

    if (found) {
      disciplinary.set(found.disciplinary_area);
    }
  }, [value, data.length]);

  return (
    <HStack spacing="2">
      <Select
        onChange={(e) => {
          disciplinary.set(e.target.value);
          onChange(``);
        }}
        value={disciplinary.value}
      >
        {disciplinaryarea.map(([_, item], index) => (
          <option key={index} value={item.disciplinary_area}>
            {item.disciplinary_area_desc}
          </option>
        ))}
      </Select>
      <Select onChange={(e) => onChange(e.target.value)} value={value}>
        {filtered.map((item, index) => (
          <option key={index} value={item.id}>
            {item.disciplinary_area_title}
          </option>
        ))}
      </Select>
    </HStack>
  );
};
