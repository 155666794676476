import { anyof, isexact } from 'tiinvo';

export const dark = `dark`;
export const light = `light`;
export const system = `system`;

export type dark = typeof dark;
export type light = typeof light;
export type system = typeof system;

export type ThemeOption = dark | light | system;

//#region typeguards

export const isdark = isexact<dark>(dark);
export const islight = isexact<light>(light);
export const issystem = isexact<system>(system);
export const isThemeOption = anyof<ThemeOption>(isdark, islight, issystem);

//#endregion
