import { useAuth } from 'app/AuthProvider';
import * as authsdk from 'lib/sdk.client/auth';
import { useEffect, useState } from 'react';
import { array, obj, pipe, predicate } from 'tiinvo';

const mustvalidateemail = pipe(
  obj.mapkey('missingData'),
  array.includes(`email_verified_at`),
);

const maproute = (url: [key: string, url: string]) => (data: any) => {
  if (obj.mapkey('userComplete')(data)) {
    return url;
  } else if (mustvalidateemail(data)) {
    return `/auth/sign-up/verification-pending`;
  } else {
    return `/auth/sign-up/complete`;
  }
};

const isusercomplete = pipe(
  obj.mapkey(`value`),
  obj.mapkey(`userComplete`),
  predicate.withsamevalue(true),
);

export const useSafePersonalLinkMap = <t extends Record<string, string>>(
  map: t,
): t => {
  const [remapped, setremapped] = useState(map);
  const user = useAuth().user;
  const keys = obj.entries(remapped);
  const handler = () => {
    if (user) {
      authsdk.isusercomplete(user.uuid).then((r) => {
        if (!isusercomplete(r)) {
          let m = {} as any;

          for (const key of keys) {
            m[key[0]] = maproute(key)(r.value);
          }

          setremapped(m);
        }
      });
    }
  };

  useEffect(() => {
    handler();
  }, [user]);

  useEffect(() => {
    const id = setInterval(handler, 5000);
    return () => clearInterval(id);
  }, []);

  return remapped;
};
