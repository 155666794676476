import jwt from 'jsonwebtoken';

export type UserType = 'admin' | 'user';

export type CreateJWTClaims = {
  user: {
    type: string;
    uuid: string;
    email: string;
    username: string;
  };
};

export type JWTClaims = {
  user: {
    type: string;
    uuid: string;
    email: string;
    username: string;
  };
  'https://hasura.io/jwt/claims': {
    'x-hasura-allowed-roles': string[];
    'X-Hasura-Role': 'user' | 'superuser';
    'x-hasura-default-role': 'user' | 'superuser';
    'x-hasura-user-type': string;
    'x-hasura-user-id': string;
    'x-hasura-user-username': string;
    'x-hasura-user-email': string;
  };
};

const { HASURA_GRAPHQL_JWT_SECRET_KEY } = process.env;

export const createJWT = (data: CreateJWTClaims): string => {
  const hasuraRole = data.user.type === 'superuser' ? 'superuser' : 'user';
  const jwtContent = {
    ...data,
    'https://hasura.io/jwt/claims': {
      'X-Hasura-Allowed-Roles': ['superuser', 'user'],
      'X-Hasura-Default-Role': hasuraRole,
      'X-Hasura-Role': hasuraRole,
      'X-Hasura-User-Type': data.user.type,
      'X-Hasura-User-Id': data.user.uuid,
      'X-Hasura-User-Username': data.user.username,
      'X-Hasura-User-Email': data.user.email,
    },
  };

  return jwt.sign(jwtContent, HASURA_GRAPHQL_JWT_SECRET_KEY!);
};

export const decodeJWT = (data: string): JWTClaims =>
  jwt.verify(data, HASURA_GRAPHQL_JWT_SECRET_KEY!) as JWTClaims;
