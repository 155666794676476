import { extendTheme } from '@chakra-ui/react';
import colors from './colors';
import components from './components';

const config = {
  useSystemColorMode: true,
};

const themeoverride = {
  config,
  colors,
  components,
};

export const theme = extendTheme(themeoverride);

export type LauraTheme = typeof theme;
