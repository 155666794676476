import { ComponentStyleConfig } from '@chakra-ui/react';

const bordermixin = {
  borderColor: `primary.500`,
  borderWidth: `1px`,
  borderStyle: `solid`,
};

const outlinevariant = {
  ...bordermixin,
  backgroundColor: `transparent`,
  borderColor: `currentColor`,
};

export default {
  defaultProps: {
    variant: `secondary`,
  },
  variants: {
    primary: {
      backgroundColor: `primary.500`,
      ...bordermixin,
      color: `white`,
    },
    secondary: {
      backgroundColor: `white`,
      ...bordermixin,
      color: `primary.500`,
    },
    primaryOutline: {
      color: `primary.500`,
      ...outlinevariant,
    },
    secondaryOutline: {
      color: `white`,
      ...outlinevariant,
    },
  },
} as ComponentStyleConfig;
