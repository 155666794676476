import { Box, Divider, DividerProps, Text } from '@chakra-ui/react';
import React, { FC } from 'react';

export interface DividerWithTextProps extends DividerProps {}

export const DividerWithText: FC<DividerWithTextProps> = ({
  children,
  ...props
}) => {
  return (
    <Box position="relative">
      <Divider {...props} />
      <Text
        left="50%"
        position="absolute"
        top="50%"
        transform="translate(-50%, -50%)"
        variant="t8"
      >
        {children}
      </Text>
    </Box>
  );
};
