import { IsUserCompleteResponse } from '@typings/auth/IsUserCompleteResponse';
import type { LoginRequest } from '@typings/auth/LoginRequest';
import type { RegisterRequest } from '@typings/auth/RegisterRequest';
import { VerifyRequest } from '@typings/auth/VerifyRequest';
import { either } from 'tiinvo';
import * as api from './api';

const client = api.create(api.baseURL(`/v1/auth`));

const isusercompleterequest = (uuid: string) =>
  client.get(`/isUserComplete?uuid=${uuid}`);
const loginrequest = (request: LoginRequest) => client.post(`/login`, request);
const registerrequest = (request: RegisterRequest) =>
  client.post(`/register`, request);
const resetpasswordrequest = (request: Record<'email', string>) =>
  client.post(`/reset-password-request`, request);
const updatepasswordrequest = (request: Record<'email' | 'password', string>) =>
  client.post(`/update-password`, request);
const verifyrequest = (params: VerifyRequest) =>
  client.get(`verify`, { params });

const isusercompletehandler = api.mapresponse<
  unknown,
  IsUserCompleteResponse | void
>({
  200: either.right,
  304: either.right,
  400: either.left,
  404: either.left,
  422: either.left,
  500: either.left,
});

const loginhandler = api.mapresponse<
  unknown,
  { jtw: string; success: boolean }
>({
  200: either.right,
  400: either.left,
  401: either.left,
  422: either.left,
  500: either.left,
});

const registerhandler = api.mapresponse<unknown, unknown>({
  200: either.right,
  400: either.left,
  401: either.left,
  409: either.left,
  422: either.left,
  500: either.left,
});

const resetpasswordhandler = api.mapresponse<unknown, unknown>({
  200: either.right,
  400: either.left,
  401: either.left,
  422: either.left,
  500: either.left,
});

const verifyhandler = api.mapresponse<unknown, unknown>({
  200: either.right,
  400: either.left,
  401: either.left,
  500: either.left,
});

export const isusercomplete = api.withstatushandler(isusercompletehandler)(
  isusercompleterequest,
);
export const login = api.withstatushandler(loginhandler)(loginrequest);
export const register = api.withstatushandler(registerhandler)(registerrequest);
export const resetpassword =
  api.withstatushandler(resetpasswordhandler)(resetpasswordrequest);
export const updatepassword = api.withstatushandler(resetpasswordhandler)(
  updatepasswordrequest,
);
export const verify = api.withstatushandler(verifyhandler)(verifyrequest);
