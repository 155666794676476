import { Heading, HStack, Text } from '@chakra-ui/layout';
import React, { FC } from 'react';
import { Paper, PaperProps } from '../shapes/Paper';
import { UserAvatar } from './UserAvatar';
import { UserRole } from './UserRole';

export interface UserCardProps extends PaperProps {
  avatar: string;
  email: string;
  username: string;
  usertype: string | string[];
}

const ensurearray = (arg: string | string[]) =>
  Array.isArray(arg) ? arg : [arg];

export const UserCard: FC<UserCardProps> = ({
  avatar,
  email,
  username,
  usertype,
  ...paperprops
}) => {
  const types = ensurearray(usertype);

  return (
    <Paper
      alignItems="center"
      boxShadow="md"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      maxW="56"
      padding="6"
      textAlign="center"
      {...paperprops}
    >
      <UserAvatar avatar={avatar} />
      <HStack>
        {types.map((type, index) => (
          <UserRole role={type} key={index} />
        ))}
      </HStack>
      <Heading
        fontWeight="bold"
        marginBottom="0"
        overflow="hidden"
        textOverflow="ellipsis"
        variant="h4"
        whiteSpace="nowrap"
        width="full"
      >
        {username}
      </Heading>
      <Text
        as="a"
        fontWeight="light"
        href={`mailto:${email}`}
        overflow="hidden"
        textOverflow="ellipsis"
        variant="t8"
        whiteSpace="nowrap"
        width="full"
      >
        {email}
      </Text>
    </Paper>
  );
};
