import { useAuth } from 'app/AuthProvider';
import { createElement, FC } from 'react';
import { AuthUserReactions } from './AuthUserReactions';
import { UnauthUserReactions } from './UnauthUserReactions';

type ContentType = 'thesis'; // we may want to accept reactions for other kind of contents in the future

export type ReactionData = {
  id: string;
  emoji: string;
  name: string;
  number: string;
};

export interface ReactionsProps {
  isMyThesis?: boolean;
  userId: string;
  contentId: string;
  contentType: ContentType;
}

export const Reactions: FC<ReactionsProps> = (props) => {
  const loggedin = useAuth().isLoggedIn;
  const component =
    loggedin && !props.isMyThesis ? AuthUserReactions : UnauthUserReactions;

  return createElement(component, props);
};
