import { useColorMode } from '@chakra-ui/color-mode';
import { light } from '@typings/ui/personal/ThemeOption';
import React, { FC, useEffect, useLayoutEffect } from 'react';
import { useBool } from 'use-tiinvo';

export interface ColorModeHackProps {}

const ourkey = `anubis.lupus.deum.cagnaccium`;

/**
 * 🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨
 * 🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨
 *
 * usage:
 *
 * ```ts
 * // 🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨
 * // 🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨
 * // 🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨
 * // 🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨
 * // 🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨
 * // 🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨
 * ```
 *
 * @param param0
 * @returns
 */
export const ColorModeHack: FC<ColorModeHackProps> = ({}) => {
  const mounted = useBool();

  useEffect(() => {
    mounted.settrue();
  }, []);

  if (!mounted.value) {
    return null;
  }

  // 😅
  return <SSR />;
};

const SSR = () => {
  const rehydrated = useBool();
  const { colorMode, setColorMode } = useColorMode();

  useEffect(() => {
    if (colorMode && rehydrated.value) {
      // 🔨
      localStorage.setItem(ourkey, colorMode);
    }
  }, [rehydrated.value, colorMode]);

  useLayoutEffect(() => {
    // 🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨
    setTimeout(() => {
      // 🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨
      setColorMode(localStorage.getItem(ourkey) ?? light);
      // 🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨🔨
      rehydrated.settrue();
    }, 1);
  }, []);

  return null;
};
