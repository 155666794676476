import { Select, SelectProps } from '@chakra-ui/select';
import { useAllAtecoSectors } from '@hasura/hooks/useAllAtecoSectors';
import React, { FC } from 'react';

export interface AtecoSelectProps extends SelectProps {}

export const AtecoSelect: FC<AtecoSelectProps> = (props) => {
  const { atecosectors } = useAllAtecoSectors();

  return (
    <Select {...props}>
      {atecosectors
        .sort((a, b) => Number(a.macro_ateco) - Number(b.macro_ateco))
        .map((atecosector, index) => (
          <option key={index} value={atecosector.id}>
            {atecosector.macro_ateco} / {atecosector.ateco_macro_desc} /{' '}
            {atecosector.ateco_sector_desc}
          </option>
        ))}
    </Select>
  );
};
