import { gql, useQuery } from '@apollo/client';
import { array, obj, option, pipe } from 'tiinvo';
import { create, UserProfile } from 'types/auth/UserProfile';

const profilesafe = pipe(
  option.fromfunction(obj.mapkey('users')),
  option.map(array.first),
  option.unwrapOrElse(create),
);

const GET_USER_PROFILE_BY_ID = gql`
  query GetProfile($uuid: uuid!) {
    users(where: { id: { _eq: $uuid } }) {
      bio
      city
      country
      company_data
      email
      id
      first_name
      last_name
      profile_image_url
      resume_url
      username
      social_login_origin
      user_type
      id_university
      id_degree_course
      website
    }
  }
`;

export const useUserProfile = (uuid: string) => {
  const query = useQuery<{ users: UserProfile[] }>(GET_USER_PROFILE_BY_ID, {
    fetchPolicy: 'network-only',
    variables: {
      uuid,
    },
  });
  const userprofile = profilesafe(query.data ?? ({} as any));

  return {
    ...query,
    userprofile,
  };
};

interface GetProfile {
  users: GetProfileUser[];
}

interface GetProfileUser {
  bio: string;
  city: string;
  company_data: string;
  id: string;
  first_name: string;
  last_name: string;
  profile_image_url: string;
  resume_url: string;
  username: string;
  user_type: string;
  country: string;
  id_degree_course: string;
  id_university: string;
  professor_data: GetUserProfileProfessorData[];
  companyDataByIdUser: GetUserProfileCompanyData[];
  website: string;
}

interface GetUserProfileProfessorData {
  professor_detail: {
    id_scientific_disciplinary_sector: string;
  };
}

interface GetUserProfileCompanyData {
  business_name: string;
  city: string;
  description: string;
  id_business_detail: string;
  legal_entity: string;
  website: string;
}

const GET_USER_PROFILE_BY_USERNAME = gql`
  query GetProfile($username: String!) {
    users(where: { username: { _eq: $username } }) {
      bio
      city
      company_data
      id
      first_name
      last_name
      profile_image_url
      resume_url
      username
      user_type
      country
      id_degree_course
      website
      id_university
      professor_data {
        professor_detail {
          id_scientific_disciplinary_sector
        }
      }
      companyDataByIdUser {
        business_name
        city
        description
        id_business_detail
        legal_entity
        website
      }
    }
  }
`;

export const useUserProfileByUsername = (username: string) => {
  const query = useQuery<GetProfile>(GET_USER_PROFILE_BY_USERNAME, {
    fetchPolicy: 'network-only',
    variables: {
      username,
    },
  });

  const userprofile = query.data?.users[0]! ?? {};

  return {
    ...query,
    userprofile,
  };
};
