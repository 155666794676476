import { useFormik } from 'formik';
import { defineMessages, useIntl } from 'react-intl';
import { FnUnary } from 'tiinvo';
import * as yup from 'yup';

const messages = defineMessages({
  requiredstring: {
    id: 'ui.company.useCompanyData.requiredstring',
    defaultMessage: 'Questo campo è obbligatorio',
  },
});

export type useCompanyDataForm = typeof useCompanyDataForm;

export interface CompanyDataFormInitialValues {
  user_id?: string | null;
  first_name: string | null;
  last_name: string | null;
  business_name: string | null;
  id_business_detail: string | null;
}

export const useCompanyDataForm = (
  initialValues: CompanyDataFormInitialValues,
  onSubmit: FnUnary<CompanyDataFormInitialValues, Promise<any> | any> = () =>
    Promise.resolve(),
) => {
  const { formatMessage } = useIntl();
  const requiredstringmessage = formatMessage(messages.requiredstring);
  const validationSchema = yup.object({
    first_name: yup.string().required(requiredstringmessage),
    last_name: yup.string().required(requiredstringmessage),
    business_name: yup.string().required(requiredstringmessage),
    id_business_detail: yup.string().required(requiredstringmessage),
  });

  return useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit,
  });
};
