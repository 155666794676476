import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { FooterLink } from './FooterLink';

export interface PrivacyLinkProps {}

export const PrivacyLink: FC<PrivacyLinkProps> = ({}) => {
  return (
    <FooterLink href="/privacy">
      <FormattedMessage
        defaultMessage="Privacy"
        id="ui.theme.layout.footer.PrivacyLink"
      />
    </FooterLink>
  );
};
