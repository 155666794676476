// import Image from 'next/image';
import { ImageProps, Box } from '@chakra-ui/react';
import React, { FC } from 'react';

export interface BackgroundImageProps
  extends Omit<ImageProps, 'position' | 'left' | 'top' | 'right' | 'bottom'> {}

export const BackgroundImage: FC<BackgroundImageProps> = ({ ...props }) => {
  return (
    <Box>
      <Box
        backgroundImage={props.src!}
        backgroundSize="cover"
        objectFit="cover"
        objectPosition="center"
        w="full"
        h="full"
        position="absolute"
      />
      {/* <Image
        src={props.src!}
        layout="fill"
        objectFit="cover"
        objectPosition="center"
      /> */}
      <Box pos="absolute" w="full" h="full" bgColor="blackAlpha.500"></Box>
    </Box>
  );
};
