import { Text } from '@chakra-ui/layout';
import { Box, Button, ButtonGroup, Heading, HStack } from '@chakra-ui/react';
import { UserAvatar } from 'app/ui/user';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { bind, FnUnary, pass } from 'tiinvo';
import * as TUserProfile from 'types/auth/UserProfile';

export interface UserProps {
  profile: TUserProfile.UserProfile;
  onEdit?: FnUnary<TUserProfile.UserProfile, any>;
  onRemove?: FnUnary<TUserProfile.UserProfile, any>;
}

export const User: FC<UserProps> = ({
  profile,
  onEdit = pass,
  onRemove = pass,
}) => {
  return (
    <HStack alignItems="center" spacing="4" width="full">
      <UserAvatar
        avatar={TUserProfile.mapprofile_image_urlsafe(profile)}
        margin="0"
        size="10"
      />
      <Box flex="1 0 auto">
        <Heading fontWeight="black" margin="0" variant="h8">
          @{TUserProfile.mapusername(profile)}
        </Heading>
        <Text variant="t8">{TUserProfile.mapfullname(profile)}</Text>
        <Text opacity=".54" variant="t8">
          {TUserProfile.mapemail(profile)}
        </Text>
      </Box>
      <Box justifySelf="flex-end">
        <ButtonGroup spacing="1.5" size="xs">
          <Button
            colorScheme="red"
            onClick={bind(onRemove, profile)}
            variant="solid"
          >
            <FormattedMessage
              defaultMessage="Elimina"
              id="ui.admin.user.User.remove"
            />
          </Button>
          <Button
            colorScheme="primary"
            onClick={bind(onEdit, profile)}
            variant="solid"
          >
            <FormattedMessage
              defaultMessage="Modifica"
              id="ui.admin.user.User.edit"
            />
          </Button>
        </ButtonGroup>
      </Box>
    </HStack>
  );
};
