import { anyof, isexact } from 'tiinvo';

export const facebook = `facebook`;
export const linkedin = `linkedin`;
export const twitter = `twitter`;
export const youtube = `youtube`;
export const google = `google`;

export type facebook = typeof facebook;
export type linkedin = typeof linkedin;
export type twitter = typeof twitter;
export type youtube = typeof youtube;
export type google = typeof google;

export type Social = facebook | linkedin | twitter | youtube | google;

//#region typeguards

export const isfacebook = isexact<facebook>(facebook);
export const islinkedin = isexact<linkedin>(linkedin);
export const istwitter = isexact<twitter>(twitter);
export const isyoutube = isexact<youtube>(youtube);
export const isgoogle = isexact<google>(google);

export const isSocial = anyof<Social>(
  isfacebook,
  islinkedin,
  istwitter,
  isyoutube,
  isgoogle,
);

//#endregion
