import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Textarea,
} from '@chakra-ui/react';
import { useUserProfile } from '@hasura/hooks/useUserProfile';
import { useFormik } from 'formik';
import React, { FC, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import { CitySelector } from '../city';
import { DisciplinarySectorSelector } from '../disciplinarysector';
import { ProfileDataUpdateActions } from '../form/ProfileDataUpdateActions';
import { UniversityPicker } from '../university';
import { WebsiteInput } from '../form/WebsiteInput';
import { UserProfileEditableFormProps } from '../UserProfileEditableFormProps';
import { BiUpload } from 'react-icons/bi';
import { FileSelector } from '..';
import { useFileDownload } from 'app/useFileDownload';

interface ProfessorDetail {
  id: string;
  id_scientific_disciplinary_sector: string;
}

interface GetProfessorDetail {
  professor_detail: ProfessorDetail[];
}

const querydata = gql`
  query GetProfessorData($uuid: uuid!) {
    professor_data(where: { id_user: { _eq: $uuid } }) {
      id_professor_detail
    }
  }
`;

const querydetail = gql`
  query GetProfessorDetail($id: uuid!) {
    professor_detail(where: { id: { _eq: $id } }) {
      id
      id_scientific_disciplinary_sector
    }
  }
`;

const usermutation = gql`
  mutation updateUserProfile(
    $first_name: String
    $last_name: String
    $bio: String
    $city: String
    $id_university: uuid
    $uuid: uuid
    $website: String
    $resume_url: String
  ) {
    update_users(
      where: { id: { _eq: $uuid } }
      _set: {
        first_name: $first_name
        last_name: $last_name
        bio: $bio
        city: $city
        id_university: $id_university
        website: $website
        resume_url: $resume_url
      }
    ) {
      affected_rows
    }
  }
`;

const professormutation = gql`
  mutation UpdateProfessorData(
    $id: uuid!
    $id_scientific_disciplinary_sector: uuid!
  ) {
    update_professor_detail(
      where: { id: { _eq: $id } }
      _set: {
        id_scientific_disciplinary_sector: $id_scientific_disciplinary_sector
      }
    ) {
      affected_rows
    }
  }
`;

export const ProfessorDataFormEdit: FC<UserProfileEditableFormProps> = ({
  onSave,
  canmodifyresume,
  isuploadingresume,
  onUploadResume,
  uuid,
  disabled,
}) => {
  const user = useUserProfile(uuid).userprofile;
  const professordataid = useQuery(querydata, { variables: { uuid } }).data
    ?.professor_data?.[0]?.id_professor_detail;
  const professordata = useQuery<GetProfessorDetail>(querydetail, {
    variables: {
      id: professordataid,
    },
    skip: !professordataid,
  }).data?.professor_detail[0] ?? {
    id: ``,
    id_scientific_disciplinary_sector: ``,
  };
  const [mutateuser, mutateuserstate] = useMutation(usermutation);
  const [mutateprofessordetail, mutateprofessordetailstate] =
    useMutation(professormutation);
  const form = useFormik({
    initialValues: {
      professordata,
      user,
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      user: yup.object({
        first_name: yup.string().required(`Questo campo è obbligatorio`),
        last_name: yup.string().required(`Questo campo è obbligatorio`),
        bio: yup.string().nullable(),
        city: yup.string().nullable(),
        website: yup
          .string()
          .url(`Una URL valida è per esempio https://www.google.com`)
          .notRequired()
          .nullable(),
      }),
      professordata: yup.object({
        id_scientific_disciplinary_sector: yup
          .string()
          .required(`Questo campo è obbligatorio`),
      }),
    }),
    onSubmit: ({ professordata, user }) => {
      const { id, id_scientific_disciplinary_sector } = professordata;
      const {
        first_name,
        last_name,
        bio,
        city,
        id_university,
        website,
        resume_url,
      } = user;

      mutateuser({
        variables: {
          first_name,
          last_name,
          bio,
          city,
          uuid,
          id_university,
          website,
          resume_url,
        },
      });

      mutateprofessordetail({
        variables: {
          id_scientific_disciplinary_sector,
          id,
        },
      });
    },
  });

  useEffect(() => {
    if (
      !mutateprofessordetailstate.loading &&
      !mutateuserstate.loading &&
      mutateprofessordetailstate.data &&
      mutateuserstate.data
    ) {
      onSave && onSave(true);
      form.resetForm({
        values: form.values,
      });
    }
  }, [
    mutateuserstate.data,
    mutateprofessordetailstate.data,
    mutateprofessordetailstate.loading,
    mutateuserstate.loading,
  ]);

  const handledownload = useFileDownload(form.values.user.resume_url);

  return (
    <>
      <FormControl isInvalid={!!form.errors.user?.first_name}>
        <FormLabel>
          <FormattedMessage
            defaultMessage="Nome"
            id="ui.professor.ProfessorDataForm.first_name"
          />
        </FormLabel>
        <Input
          name="user.first_name"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.user?.first_name ?? ``}
        />
        <FormErrorMessage>{form.errors.user?.first_name}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!form.errors.user?.last_name}>
        <FormLabel>
          <FormattedMessage
            defaultMessage="Cognome"
            id="ui.professor.ProfessorDataForm.last_name"
          />
        </FormLabel>
        <Input
          name="user.last_name"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.user?.last_name ?? ``}
        />
        <FormErrorMessage>{form.errors.user?.last_name}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!form.errors.user?.bio}>
        <FormLabel>
          <FormattedMessage
            defaultMessage="Biografia"
            id="ui.professor.ProfessorDataForm.biod"
          />
        </FormLabel>
        <Textarea
          name="user.bio"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.user?.bio ?? ``}
        />
        <FormErrorMessage>{form.errors.user?.bio}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!form.errors.user?.city}>
        <FormLabel>
          <FormattedMessage
            defaultMessage="Città"
            id="ui.professor.ProfessorDataForm.city"
          />
        </FormLabel>
        <CitySelector
          onChange={(city) => {
            form.setFieldValue('user.city', city, true);
            form.setFieldTouched('user.city', true, true);
          }}
          value={form.values.user?.city ?? ``}
        />
        <FormErrorMessage>{form.errors.user?.city}</FormErrorMessage>
      </FormControl>
      <FormControl
        isInvalid={
          !!form.errors.professordata?.id_scientific_disciplinary_sector
        }
      >
        <FormLabel>
          <FormattedMessage
            id="ui.professor.id_scientific_disciplinary_sector.type"
            defaultMessage="Settore Scientifico Disciplinare"
          />
        </FormLabel>
        <DisciplinarySectorSelector
          onChange={(id_scientific_disciplinary_sector) => {
            form.setFieldValue(
              'professordata.id_scientific_disciplinary_sector',
              id_scientific_disciplinary_sector,
              true,
            );
            form.setFieldTouched(
              'professordata.id_scientific_disciplinary_sector',
              true,
              true,
            );
          }}
          value={
            form.values.professordata?.id_scientific_disciplinary_sector ?? ``
          }
        />
        <FormErrorMessage>
          {form.errors.professordata?.id_scientific_disciplinary_sector}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!form.errors.user?.id_university}>
        <FormLabel>
          <FormattedMessage
            id="ui.professor.ProfessorData.university"
            defaultMessage="Università"
          />
        </FormLabel>
        <UniversityPicker
          onChange={(id_university) => {
            form.setFieldValue('user.id_university', id_university, true);
            form.setFieldTouched('user.id_university', true, true);
          }}
          value={form.values.user?.id_university ?? ``}
        />
        <FormErrorMessage>{form.errors.user?.id_university}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!form.errors.user?.website}>
        <FormLabel>
          <FormattedMessage
            id="ui.professor.ProfessorData.website"
            defaultMessage="Sito web"
          />
        </FormLabel>
        <WebsiteInput
          name="user.website"
          onBlur={form.handleBlur}
          onChange={form.handleChange}
          value={form.values.user?.website ?? ``}
        />
        <FormErrorMessage>{form.errors.user?.website}</FormErrorMessage>
      </FormControl>

      <HStack
        alignItems="center"
        justifyContent="center"
        spacing="4"
        width="full"
      >
        {canmodifyresume && (
          <FileSelector
            accepts=".pdf"
            colorScheme="primary"
            isLoading={isuploadingresume}
            leftIcon={<BiUpload />}
            onSelect={(files) => onUploadResume && form.submitForm().then(() => onUploadResume(files[0]))}
            size="sm"
            variant="solid"
          >
            {!!form.values.user.resume_url && (
              <FormattedMessage
                defaultMessage="Aggiorna Curriculum"
                id="ui.user.UserProfile.edit.resume_url.update"
              />
            )}
            {!form.values.user.resume_url && (
              <FormattedMessage
                defaultMessage="Carica Curriculum"
                id="ui.user.UserProfile.edit.resume_url.upload"
              />
            )}
          </FileSelector>
        )}
        {!!form.values.user.resume_url && (
          <>
            <Button
              onClick={handledownload}
              marginTop="4"
              textDecoration="underline"
              size="sm"
              variant="link"
            >
              <FormattedMessage
                defaultMessage="Scarica Curriculum"
                id="ui.user.UserProfile.edit.resume_url.download"
              />
            </Button>
          </>
        )}
      </HStack>

      <ProfileDataUpdateActions disabled={disabled} form={form} />
    </>
  );
};
