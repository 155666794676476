import { LauraTheme, theme as laura } from './laura';
import { SofiaTheme, theme as sofia } from './sofia';

export const themes = {
  laura,
  sofia,
};

export type Theme = LauraTheme | SofiaTheme;

export type ThemeNames = keyof typeof themes;
