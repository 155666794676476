import { array, num, pipe } from 'tiinvo';

export interface Paginated<T extends any[] = any[]> {
  readonly current: number;
  readonly items: T;
  readonly page: T;
  readonly pages: number;
  readonly perpage: number[];
  readonly limit: number;
}

//#region factories

export const create = <T extends any[]>(
  partial: Partial<Paginated<T>> = {},
): Paginated<T> => {
  const perpage = partial.perpage ?? [10, 20, 50];
  const items =
    partial.items ?? (Array.from(new Array(partial.page ?? 0)) as unknown as T);
  const limit = partial.limit ?? perpage[0];
  const current = partial.current ?? 0;
  const page =
    partial.page ?? (items.slice(current * limit, (current + 1) * limit) as T);
  const pages = partial.pages ?? Math.ceil(items.length / limit);

  return {
    current,
    items,
    limit,
    page,
    pages,
    perpage,
  };
};

//#endregion

//#region mappables

export const mapitems = <T extends any[]>(paginated: Paginated<T>) =>
  paginated.items;
export const mappages = <T extends any[]>(paginated: Paginated<T>) =>
  paginated.pages;

export const mapprevpage = <T extends any[]>(
  paginated: Paginated<T>,
): Paginated<T> =>
  create({
    ...paginated,
    current:
      paginated.current - 1 >= 0 ? paginated.current - 1 : paginated.pages - 1,
  });

export const mapnextpage = <T extends any[]>(
  paginated: Paginated<T>,
): Paginated<T> =>
  create({
    ...paginated,
    current:
      paginated.current + 1 <= paginated.pages - 1 ? paginated.current + 1 : 0,
  });

//#endregion

//#region predicates

export const isempty = pipe(mapitems, array.empty);
export const haspages = pipe(mappages, num.greaterthan(0));
export const hasonepage = pipe(mappages, num.equals(1));

//#endregion
