import {
  fallback,
  isnullOrUndefined,
  result,
  toasync,
  trycatchAsync,
} from 'tiinvo';
import { useAuth } from './AuthProvider';

export const useFileDownload = (fileURL: string | undefined | null) => {
  const jwt = useAuth().jwt;

  if (isnullOrUndefined(fileURL)) {
    return toasync(fallback(false));
  }

  return async () => {
    const httpres = await trycatchAsync(fetch, `/v1/files/generateSignedURL`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify({
        fileURL,
      }),
    });

    if (result.isErr(httpres)) {
      return false;
    }

    const { success, data } = await result.unwrap(httpres).json();

    if (success) {
      window.open(data, '_blank');
    }

    return success;
  };
};
