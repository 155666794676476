import { gql, useMutation } from '@apollo/client';
import { company } from '@typings/auth/UserRole';
import { useEffect } from 'react';
import { FnUnary } from 'tiinvo';
import { useCompanyDataForm } from './useCompanyDataForm';

const UpdateUserProfile = gql`
  mutation updateCompanyProfile(
    $first_name: String
    $last_name: String
    $user_type: String
    $user_id: uuid
  ) {
    update_users(
      where: { id: { _eq: $user_id } }
      _set: {
        first_name: $first_name
        last_name: $last_name
        user_type: $user_type
      }
    ) {
      affected_rows
    }
  }
`;

const InsertCompanyData = gql`
  mutation insertCompanyData(
    $user_id: uuid!
    $business_name: String
    $legal_entity: String
    $id_business_detail: uuid!
  ) {
    insert_company_data_one(
      object: {
        id_business_detail: $id_business_detail
        business_name: $business_name
        legal_entity: $legal_entity
        id_user: $user_id
      }
    ) {
      id
    }
  }
`;

export const useCreateCompany = (
  user_id?: string,
  onSave?: FnUnary<boolean, any>,
) => {
  const [update, updatestate] = useMutation(UpdateUserProfile);
  const [insert, insertstate] = useMutation(InsertCompanyData);
  const form = useCompanyDataForm(
    {
      user_id,
      business_name: '',
      first_name: '',
      last_name: '',
      id_business_detail: ``,
    },
    (values) => {
      update({
        variables: {
          first_name: values.first_name,
          last_name: values.last_name,
          user_id,
          user_type: company,
        },
      });
    },
  );

  useEffect(() => {
    if (updatestate.data) {
      onSave && onSave(true);
    }
  }, [updatestate]);

  useEffect(() => {
    if (updatestate.data) {
      insert({
        variables: {
          user_id,
          id_business_detail: form.values.id_business_detail,
          business_name: form.values.business_name,
          legal_entity: ``,
        },
      });
    }
  }, [updatestate.data]);

  return {
    success: !!updatestate.data && !!insertstate.data,
    form,
  };
};
