import { Text } from '@chakra-ui/layout';
import Link from 'next/link';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Activity, ActivityProps } from './Activity';

export interface ThesisReactionProps extends ActivityProps {}

export const ThesisReaction: FC<ThesisReactionProps> = ({ activity }) => {
  return (
    <Activity activity={activity}>
      <FormattedMessage
        defaultMessage="Aggiunto la reazione {0} alla tesi {1}"
        id="pages.laura.u.ThesisReaction"
        values={{
          0: activity.reactions.emoji,
          1: (
            <Link href={`/thesis/${activity.thesis.slug}`} passHref>
              <Text as="a" fontSize="sm" color="blue.500">
                {activity.thesis.title}
              </Text>
            </Link>
          ),
        }}
      />
    </Activity>
  );
};
