import {
  Box,
  Flex,
  Icon,
  IconButton,
  SlideFade,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { useReactionActions } from 'app/catalog/hooks/useReactionActions';
import type { FC } from 'react';
import { useState } from 'react';
import { AiOutlineSmile } from 'react-icons/ai';
import { isnullOrUndefined } from 'tiinvo';
import { useNeutralPalette } from '../useNeutralPalette';
import { usePalette } from '../usePalette';
import { ReactionsProps } from './Reactions';

export const AuthUserReactions: FC<ReactionsProps> = (props) => {
  const { isOpen, onOpen, onToggle } = useDisclosure();
  const [openTimeout, setOpenTimeout] = useState<any>();
  const neutrals = useNeutralPalette();
  const primary = usePalette('primary', true);
  const { reactions, userReactions, ...actions } = useReactionActions(
    props.contentId,
  );

  const totalReactions =
    reactions?.reactions.reduce((acc, val) => acc + val.number, 0) ?? 0;

  const handleOpen = () => {
    clearOpenTimeout();
    onOpen();
  };

  const handleOpenTimeout = () => setOpenTimeout(setTimeout(onToggle, 500));

  const clearOpenTimeout = () => clearTimeout(openTimeout);

  const reactionDynamicBg = (id: string) =>
    userReactions.includes(id) ? primary[100] : neutrals[50];

  const handleReactionMutation = (id: string) => {
    const isdeleting = userReactions.includes(id);
    const method = isdeleting ? actions.delete : actions.insert;

    method(id);
  };

  if (isnullOrUndefined(reactions)) {
    return null;
  }

  return (
    <Box>
      <SlideFade in={isOpen} offsetY="10px" unmountOnExit>
        <Box pos="relative">
          <Flex
            p="2"
            pos="absolute"
            top="-50px"
            left="50%"
            backgroundColor={neutrals[50]}
            color={neutrals[900]}
            boxShadow="md"
            borderRadius="full"
            justifyContent="space-between"
            transform="auto"
            translateX="-50%"
            onMouseOver={clearOpenTimeout}
            onMouseLeave={handleOpenTimeout}
          >
            {reactions.reactions.map((reaction, i) => (
              <Box
                key={reaction.id}
                ml={i === 0 ? '0' : '2'}
                mr={i === reactions.reactions.length - 1 ? '0' : '2'}
                _hover={{ transform: 'translateY(-3px)' }}
                transition="ease 0.2s"
                onClick={() => handleReactionMutation(reaction.id)}
              >
                <Tooltip
                  placement="top"
                  label={`${reaction.number} ${reaction.name}`}
                  hasArrow
                  isDisabled={!isOpen}
                  gutter={12}
                >
                  <Flex
                    cursor="pointer"
                    width="7"
                    height="7"
                    bgColor={reactionDynamicBg(reaction.id)}
                    borderRadius="full"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {reaction.emoji}
                  </Flex>
                </Tooltip>
              </Box>
            ))}
          </Flex>
        </Box>
      </SlideFade>

      <Flex p="2" justifyItems="center">
        <IconButton
          aria-label="Reactions"
          icon={<Icon as={AiOutlineSmile} />}
          isRound
          size="xs"
          bgColor={neutrals[200]}
          color={neutrals[800]}
          border="none"
          transition="ease 0.5s"
          onMouseOver={handleOpen}
          onMouseLeave={handleOpenTimeout}
        />
        <Text fontSize="md" ml="2" marginBottom="0" marginTop="0">
          {totalReactions}
        </Text>
      </Flex>
    </Box>
  );
};

export const ReactionsStory = (args: any) => {
  return (
    <Flex
      width="500px"
      height="500px"
      justifyContent="center"
      alignItems="center"
    >
      <AuthUserReactions {...args} />
    </Flex>
  );
};
