import { HStack, Button } from '@chakra-ui/react';
import { useFormik } from 'formik';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

export interface ProfileDataUpdateActionsProps {
  form: any;
  disabled?: boolean;
}

export const ProfileDataUpdateActions: FC<ProfileDataUpdateActionsProps> = ({
  form,
  disabled,
}) => {
  const untouched = !form.dirty;

  return (
    <HStack justifyContent="flex-end" width="full">
      <Button
        colorScheme="gray"
        disabled={disabled || untouched}
        onClick={(_) => form.resetForm()}
        variant="solid"
      >
        <FormattedMessage
          defaultMessage="Annulla"
          id="ui.user.UserProfile.edit.undo"
        />
      </Button>
      <Button
        colorScheme="primary"
        disabled={disabled || form.isSubmitting || !form.isValid || untouched}
        onClick={form.submitForm}
        isLoading={form.isSubmitting}
        variant="solid"
        type="submit"
      >
        <FormattedMessage
          defaultMessage="Salva"
          id="ui.user.UserProfile.edit.save"
        />
      </Button>
    </HStack>
  );
};
