import { HStack, useStyleConfig, VStack } from '@chakra-ui/react';
import { Copyright } from 'app/branding/Copyright';
import { SocialLink } from 'app/ui/social';
import React, { FC } from 'react';
import { FooterProps } from './FooterProps';
import { PrivacyLink } from './PrivacyLink';
import { TermsLink } from './TermsLink';

export const MobileFooter: FC<FooterProps> = ({ variant }) => {
  const styles = useStyleConfig(
    'AppFooter',
    { variant },
    { isMultiPart: true },
  );

  return (
    <VStack
      align="center"
      paddingX="4"
      paddingY="10"
      textAlign="center"
      __css={styles.bar}
    >
      <HStack align="center">
        {/* <SocialLink IconProps={{ fill: 'currentColor' }} social="facebook" />
        <SocialLink IconProps={{ fill: 'currentColor' }} social="linkedin" />
        <SocialLink IconProps={{ fill: 'currentColor' }} social="twitter" />
        <SocialLink IconProps={{ fill: 'currentColor' }} social="youtube" /> */}
      </HStack>
      <HStack align="center">
        <PrivacyLink />
        <TermsLink />
      </HStack>
      <Copyright />
    </VStack>
  );
};
