import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { FooterLink } from './FooterLink';

export interface TermsLinkProps {}

export const TermsLink: FC<TermsLinkProps> = ({}) => {
  return (
    <FooterLink href="/terms">
      <FormattedMessage
        defaultMessage="Terms"
        id="ui.theme.layout.footer.TermsLink"
      />
    </FooterLink>
  );
};
