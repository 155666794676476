import { Text, TextProps } from '@chakra-ui/react';
import { useTenant } from 'app/TenantProvider';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { fallback, multi } from 'tiinvo';
import * as TTenant from 'types/Tenant';

export interface CopyrightProps extends TextProps {
  projectname?: string;
}

const maptenanttoname = multi(
  fallback(`?`),
  [TTenant.islaura, `e-Laura`],
  [TTenant.issofia, `e-Sofia`],
);

export const Copyright: FC<CopyrightProps> = ({ children, ...props }) => {
  const tenant = useTenant();
  const projectname = maptenanttoname(tenant);

  return (
    <Text variant="t8" {...props}>
      <FormattedMessage
        defaultMessage="© {0} {1} - Tutti i diritti sono riservati"
        id="branding.Copyright"
        values={{
          0: new Date().getFullYear(),
          1: projectname,
        }}
      />
      {children}
    </Text>
  );
};
