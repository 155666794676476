import { ThemeComponents } from '@chakra-ui/react';
import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import Button from './Button';
import Heading from './Heading';
import Text from './Text';

export default {
  AppHeader,
  AppFooter,
  Button,
  Heading,
  Text,
} as ThemeComponents;
