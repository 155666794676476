import { createContext, FC, useContext } from 'react';
import * as TTenant from 'types/Tenant';

const context = createContext(TTenant.laura);

export const useTenant = () => {
  return useContext(context) as TTenant.Tenant;
};

export const TenantProvider: FC<Record<'tenant', TTenant.Tenant>> = ({
  children,
  tenant,
}) => {
  return <context.Provider children={children} value={tenant} />;
};
