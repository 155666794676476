import { Badge } from '@chakra-ui/layout';
import { useNeutralPalette } from 'app/ui/useNeutralPalette';
import React, { FC } from 'react';

export interface FollowersBadgeProps {}

export const FollowersBadge: FC<FollowersBadgeProps> = ({ children }) => {
  const neutrals = useNeutralPalette();

  return (
    <Badge backgroundColor={neutrals[50]} color={neutrals[700]}>
      {children}
    </Badge>
  );
};
