import { AtecoSector } from '@typings/company/AtecoSector';
import gql from 'graphql-tag';

export type GetAllAtecoSectors = Record<'business_detail', AtecoSector[]>;

const GET_ALL_ATECO_SECTORS = gql`
  query GetAllAtecoSectors {
    business_detail {
      ateco_sector
      ateco_macro_desc
      ateco_sector_desc
      business_macro_sector
      macro_ateco
      id
    }
  }
`;

export default GET_ALL_ATECO_SECTORS;
