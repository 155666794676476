import React, { FC } from 'react';
import { useAllCities } from './useAllCities';

export interface CityDisplayProps {
  cityId?: string | null;
}

export const CityDisplay: FC<CityDisplayProps> = ({ cityId }) => {
  const cities = useAllCities();
  const found = cities.find((a) => a.id === cityId);

  return <>{found?.name}</>;
};
