import React, { FC } from 'react';
import * as TSocial from 'types/Social';
import { Icon, IconProps } from './Icon';
import { Link } from './Link';
import { LinkName, LinkNameProps } from './LinkName';

export interface SocialLinkProps {
  IconProps?: Omit<IconProps, 'social'>;
  LinkNameProps?: Omit<LinkNameProps, 'social'>;
  social: TSocial.Social;
  displayIcon?: boolean;
  displayName?: boolean;
}

export const SocialLink: FC<SocialLinkProps> = ({
  IconProps,
  LinkNameProps,
  social,
  displayIcon = true,
  displayName,
  children,
}) => {
  return (
    <Link alignItems="center" display="inline-flex" social={social}>
      {displayIcon && <Icon {...IconProps} social={social} />}
      {displayName && (
        <LinkName
          {...LinkNameProps}
          marginLeft={displayIcon ? '2' : undefined}
          social={social}
        />
      )}
      {children}
    </Link>
  );
};
