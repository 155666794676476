import { Box, Input, List, ListItem } from '@chakra-ui/react';
import { useAllCities, City } from 'app/ui/city/useAllCities';
import Fuse from 'fuse.js';
import React, { FC, useEffect, useState } from 'react';
import { FnUnary, pass } from 'tiinvo';
import { useBool, useStr } from 'use-tiinvo';
import { useNeutralPalette } from '../useNeutralPalette';

export interface CitySelectorProps {
  onChange: FnUnary<string, any>;
  value: string;
}

const config = {
  isCaseSensitive: false,
  keys: ['name'] as (keyof City)[],
  // @ts-ignore
} as Fuse.IFuseOptions<City>;

export const CitySelector: FC<CitySelectorProps> = ({
  onChange = pass,
  value,
}) => {
  const cities = useAllCities();
  const [fuse, setfuse] = useState(new Fuse(cities, config));
  const [found, setFound] = useState(cities.find((a) => a.id === value));
  const term = useStr(found?.name ?? ``);
  const items = term.value
    ? fuse.search(term.value).map((a) => a.item)
    : cities;
  const selecting = useBool();
  const neutrals = useNeutralPalette();

  useEffect(() => {
    setfuse(new Fuse(cities, config));
  }, [cities.length]);

  useEffect(() => {
    const foundUniversity = cities.find((a) => a.id === value);
    setFound(foundUniversity);
    term.set(foundUniversity?.name ?? ``);
    selecting.setfalse();
  }, [value, JSON.stringify(cities)]);

  return (
    <Box position="relative">
      <Input
        onChange={(e) => {
          term.set(e.target.value);
          selecting.settrue();
        }}
        onFocus={selecting.settrue}
        value={term.value}
      />
      {selecting.value && (
        <List
          height="32"
          left="0"
          overflowX="visible"
          overflowY="scroll"
          position="absolute"
          top="full"
          width="full"
          zIndex="modal"
        >
          {items.map((course: City, index: number) => (
            <ListItem
              backgroundColor={neutrals[50]}
              cursor="pointer"
              px="2"
              py="2"
              onClick={() => {
                onChange(course?.id);
                selecting.setfalse();
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  onChange(course?.id);
                  selecting.setfalse();
                }
              }}
              key={course?.id}
              tabIndex={0}
            >
              {course?.name}
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};
