import { anyof, isexact } from 'tiinvo';

export const company = `usertype.company`;
export const contract_teacher = `usertype.contract_teacher`;
export const lecteur = `usertype.lecteur`;
export const phd_student = `usertype.phd_student`;
export const professional = `usertype.professional`;
export const research_fellow = `usertype.research_fellow`;
export const researcher = `usertype.researcher`;
export const scolarship_holder = `usertype.scolarship_holder`;
export const student = `usertype.student`;
export const superuser = `usertype.superuser`;

export type admin = `usertype.admin`;
export type company = typeof company;
export type contract_teacher = typeof contract_teacher;
export type lecteur = typeof lecteur;
export type phd_student = typeof phd_student;
export type professional = typeof professional;
export type research_fellow = typeof research_fellow;
export type researcher = typeof researcher;
export type scolarship_holder = typeof scolarship_holder;
export type student = typeof student;
export type superuser = typeof superuser;

export type UserRole =
  | admin
  | company
  | contract_teacher
  | lecteur
  | phd_student
  | professional
  | research_fellow
  | researcher
  | scolarship_holder
  | student
  | superuser;

//#region typeguards

export const isstudent = isexact<student>(student);
export const issuperuser = isexact<superuser>(superuser);
export const iscompany = isexact<company>(company);
export const isprofessional = isexact<professional>(professional);
export const islecteur = isexact<lecteur>(lecteur);
export const isresearcher = isexact<researcher>(researcher);
export const iscontract_teacher = isexact<contract_teacher>(contract_teacher);
export const isresearch_fellow = isexact<research_fellow>(research_fellow);
export const isphd_student = isexact<phd_student>(phd_student);
export const isscolarship_holder =
  isexact<scolarship_holder>(scolarship_holder);
export const isUserRole = anyof<UserRole>(
  isstudent,
  issuperuser,
  iscompany,
  isprofessional,
  islecteur,
  isresearcher,
  iscontract_teacher,
  isresearch_fellow,
  isphd_student,
  isscolarship_holder,
);

//#endregion
