import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

export interface DisciplinarySector {
  id: string;
  sector: string;
  theme: string;
}

export interface GetAllScientificDisciplinarySector {
  scientific_disciplinary_sector: DisciplinarySector[];
}

const query = gql`
  query GetAllScientificDisciplinarySector {
    scientific_disciplinary_sector(order_by: { sector: asc }) {
      id
      sector
      theme
    }
  }
`;

export const useAllDisciplinarySector = () => {
  return (
    useQuery<GetAllScientificDisciplinarySector>(query).data
      ?.scientific_disciplinary_sector ?? []
  );
};
