import { ApolloProvider } from '@apollo/react-hooks';
import { ChakraProvider } from '@chakra-ui/react';
import { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import { islaura, issofia } from '@typings/Tenant';
import { AuthProvider } from 'app/AuthProvider';
import { ColorModeHack } from 'app/ColorModeHack';
import * as locales from 'app/locales';
import { TenantProvider } from 'app/TenantProvider';
import { Layout } from 'app/ui/layout/Layout';
import { themes } from 'app/ui/themes';
import { AnimateSharedLayout } from 'framer-motion';
import type { JWTClaims } from 'lib/jwt';
import { decodeJWT } from 'lib/jwt';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { ComponentType, FC, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { fallback, isnullOrUndefined, multi, obj, option, pipe } from 'tiinvo';
import type { NodeEnv } from 'types/Node';
import createApolloClient from '../lib/hasura';
import withApollo from '../lib/hasura/withApollo';

const mapdomaintotenant = multi(
  fallback(themes.laura),
  [islaura, themes.laura],
  [issofia, themes.sofia],
);

const maplayout = pipe(
  option.fromfunction(obj.mapkey('layout')),
  option.unwrapOr<ComponentType>(Layout),
);

const App: FC<AppProps> = ({ Component, pageProps }) => {
  const router = useRouter();
  const { locale = 'it', defaultLocale } = router as any; // i don't want to cast;
  const messages = (locales as any)[locale] ?? locales.en;
  const Layout = maplayout(Component as any);

  const [user, setUser] = useState(pageProps?.jwtContent?.user);
  const [jwt, setJwt] = useState(pageProps?.jwtToken);
  const [isLoggedIn, setIsLoggedIn] = useState(pageProps?.isLoggedIn ?? false);
  const theme = mapdomaintotenant(pageProps.tenant.name);

  const hasuraHeaders = isLoggedIn ? { Authorization: `Bearer ${jwt}` } : {};

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      // @ts-expect-error
      ga.pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <AuthProvider
      isLoggedIn={isLoggedIn}
      jwt={jwt}
      setIsLoggedIn={setIsLoggedIn}
      setJwt={setJwt}
      setUser={setUser}
      user={user}
    >
      <ApolloProvider
        // @todo remove @ts-ignore after fixing Docker pipeline
        // @ts-ignore
        client={createApolloClient(null, hasuraHeaders)}
      >
        <TenantProvider tenant={pageProps.tenant.name}>
          <IntlProvider
            defaultLocale={defaultLocale}
            locale={locale}
            messages={messages}
          >
            <ChakraProvider theme={theme}>
              <ColorModeHack />
              <Head>
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1"
                />
              </Head>
              <AnimateSharedLayout type="switch">
                <Layout>
                  <Component {...pageProps} />
                </Layout>
              </AnimateSharedLayout>
            </ChakraProvider>
          </IntlProvider>
        </TenantProvider>
      </ApolloProvider>
    </AuthProvider>
  );
};

(App as any).getInitialProps = ({ ctx }: any) => {
  const env = process.env.NODE_ENV! as NodeEnv;
  const tenant = ctx?.req?.tenant as { name: string };
  const cookies = ctx?.req?.cookies;
  const authCookie = cookies?.anubis;
  const jwt = authCookie ? decodeJWT(authCookie) : (null as JWTClaims | null);
  const isLoggedIn = Boolean(jwt);
  const adminareacheck =
    ctx.req.path.startsWith(`/my/manage`) && jwt?.user?.type !== 'superuser';
  const personalareacheck =
    ctx.req.path.startsWith(`/my`) && isnullOrUndefined(jwt?.user);

  if (adminareacheck || personalareacheck) {
    ctx.res.writeHead(302, {
      Location: '/404',
    });
    ctx.res.end();
    return;
  }

  Sentry.configureScope((scope) => {
    scope.setUser(
      isLoggedIn
        ? { type: 'registered', id: jwt?.user.uuid }
        : { type: 'anonymous' },
    );
  });

  return {
    pageProps: {
      tenant,
      env,
      jwtToken: authCookie,
      jwtContent: jwt,
      isLoggedIn,
    },
  };
};

export default withApollo(App);
