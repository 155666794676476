import fetch from 'isomorphic-unfetch';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';

type HttpLinkHeaders = {
  accessToken?: string;
  'x-hasura-admin-secret'?: string;
  Authorization?: string;
};

const getHasuraEndpoint = (protocol: 'wss' | 'https' = 'wss'): string =>
  `${protocol}://hasura.e-laura.org/v1/graphql`; // @todo: inject this at server-side level

const createHttpLink = (headers: HttpLinkHeaders) =>
  new HttpLink({
    uri: getHasuraEndpoint('https'),
    credentials: 'include',
    headers, // auth token is fetched on the server side
    fetch,
  });

const createWSLink = (headers: HttpLinkHeaders) =>
  new WebSocketLink(
    new SubscriptionClient(getHasuraEndpoint('wss'), {
      lazy: true,
      reconnect: true,
      timeout: 30000,
      connectionParams: async () => {
        return {
          headers,
        };
      },
    }),
  );

export default function createApolloClient(
  initialState: any,
  headers: HttpLinkHeaders,
) {
  const ssrMode = typeof window === 'undefined';
  const link = ssrMode ? createHttpLink(headers) : createWSLink(headers);
  return new ApolloClient({
    ssrMode,
    link,
    cache: new InMemoryCache().restore(initialState),
  });
}
