import { HStack, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import { FormattedDate } from 'react-intl';

export interface ActivityProps {
  activity: any;
}

export const Activity: FC<ActivityProps> = ({ activity, children }) => {
  return (
    <HStack alignItems="center" borderRadius="lg">
      <Text color="gray.500" fontSize="sm" margin="0">
        <FormattedDate
          day="2-digit"
          month="2-digit"
          year="numeric"
          value={activity.created_at}
        />
      </Text>
      <Text fontSize="sm">{children}</Text>
    </HStack>
  );
};
