import { Flex, FlexProps } from '@chakra-ui/react';
import React, { FC } from 'react';

export interface ContentProps extends FlexProps {}

export const Content: FC<ContentProps> = ({ children, ...props }) => {
  return (
    <Flex
      direction="column"
      height="100%"
      position="relative"
      justifyContent="center"
      padding="10"
      zIndex="1"
      {...props}
    >
      {children}
    </Flex>
  );
};
