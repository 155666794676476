import { Link as CLink, LinkProps as CLinkProps } from '@chakra-ui/react';
import React, { FC } from 'react';
import { isundefined, multi } from 'tiinvo';
import * as TSocial from 'types/Social';

export interface LinkProps extends Omit<CLinkProps, 'href'> {
  social: TSocial.Social;
}

//#region mappables

const maptolink = multi(
  void 0,
  [TSocial.isfacebook, 'https://facebook.com/'],
  [TSocial.islinkedin, 'https://linkedin.com/'],
  [TSocial.istwitter, 'https://twitter.com/'],
  [TSocial.isyoutube, 'https://youtube.com/'],
);

//#endregion

export const Link: FC<LinkProps> = ({ social, ...props }) => {
  const url = maptolink(social);

  if (isundefined(url)) {
    return <></>;
  }

  return <CLink href={url} target="_blank" {...props} />;
};
