import { gql, useQuery } from '@apollo/client';

export interface DegreeCourse {
  id: string;
  id_macro_subject_area: string | null;
  id_subject_area: string | null;
  title: string | null;
}

export interface GetAllDegreeCourses {
  degree_courses: DegreeCourse[];
}

const query = gql`
  query GetAllDegreeCourses {
    degree_courses(order_by: { title: asc }) {
      id
      id_macro_subject_area
      id_subject_area
      title
    }
  }
`;

export const useAllDegreeCourses = (): DegreeCourse[] =>
  useQuery<GetAllDegreeCourses>(query).data?.degree_courses ?? [];
