import { LayoutProps } from '@chakra-ui/react';
import React, { FC, ReactNode } from 'react';
import { Subtitle } from '.';
import { BackgroundImage } from './BackgroundImage';
import { Container } from './Container';
import { Content, ContentProps } from './Content';
import { Title } from './Title';

export interface HeroProps {
  ContentProps?: ContentProps;
  src?: string;
  subtitle?: ReactNode;
  title?: ReactNode;
  minHeight?: LayoutProps['minHeight'];
  isThesisDetail?: boolean;
}

export const Hero: FC<HeroProps> = ({
  ContentProps,
  children,
  src,
  minHeight = '52',
  subtitle,
  title,
  isThesisDetail = false,
}) => {
  return (
    <Container bgColor={isThesisDetail ? 'blackAlpha.700' : 'white'}>
      {src && <BackgroundImage opacity=".2" src={src} />}
      <Content minHeight={minHeight} {...ContentProps}>
        {title && <Title>{title}</Title>}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        {children}
      </Content>
    </Container>
  );
};
