import { Flex, FlexProps } from '@chakra-ui/react';
import React, { FC } from 'react';

export interface ContainerProps extends FlexProps {}

export const Container: FC<ContainerProps> = (props) => {
  return (
    <Flex
      direction="column"
      justifyContent="space-around"
      position="relative"
      {...props}
    />
  );
};
