import { useBreakpointValue } from '@chakra-ui/react';
import { ComponentType, createElement, Fragment } from 'react';
import { isundefined } from 'tiinvo';

export const withBreakpointValue = <T>(
  breakpoints: Record<string, ComponentType<T>>,
): ComponentType<T> => {
  return (props) => {
    const Component = useBreakpointValue(breakpoints);
    return isundefined(Component)
      ? createElement(Fragment)
      : createElement(Component, props);
  };
};
