import { gql } from '@apollo/client';
import { Professional } from 'types/professionals/Professional';

export interface getAllProfessionals {
  professionals: Professional[];
}

const GET_ALL_PROFESSIONALS = gql`
  query getAllProfessionals {
    professionals {
      code
      id
      name
    }
  }
`;

export default GET_ALL_PROFESSIONALS;
