import { Button, ButtonProps } from '@chakra-ui/button';
import { chakra } from '@chakra-ui/react';
import React, { FC } from 'react';
import { FnUnary, pass } from 'tiinvo';

export interface FileSelectorProps extends Omit<ButtonProps, 'onSelect'> {
  accepts?: string;
  onSelect?: FnUnary<File[], any>;
}

export const FileSelector: FC<FileSelectorProps> = ({
  accepts = '.png,.bpm,.jpg',
  children,
  onSelect = pass,
  ...buttonprops
}) => {
  return (
    <Button position="relative" {...buttonprops}>
      {children}
      <chakra.input
        accept={accepts}
        cursor="pointer"
        height="full"
        left="0"
        onChange={(event) => onSelect(Array.from(event.target.files!))}
        opacity="0"
        position="absolute"
        top="0"
        type="file"
        value=""
        width="full"
      />
    </Button>
  );
};
