import { LinkIcon } from '@chakra-ui/icons';
import {
  Input,
  InputGroup,
  InputProps,
  InputLeftAddon,
} from '@chakra-ui/react';
import React, { FC } from 'react';

export interface WebsiteInputProps extends InputProps {}

export const WebsiteInput: FC<WebsiteInputProps> = (props) => {
  return (
    <InputGroup>
      <InputLeftAddon>
        <LinkIcon />
      </InputLeftAddon>
      <Input {...props} />
    </InputGroup>
  );
};
