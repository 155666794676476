import { isNullableType } from 'graphql';
import {
  array,
  implementing,
  isnull,
  isstring,
  nullable,
  option,
  pipe,
  predicate,
} from 'tiinvo';
import * as TUserType from './UserRole';

export interface UserProfile {
  bio: string | null;
  city: string | null;
  country: string | null;
  id: string;
  company_data: string | null;
  email: string;
  first_name: string | null;
  last_name: string | null;
  profile_image_url: string | null;
  resume_url: string | null;
  user_type: TUserType.UserRole;
  username: string;
  id_university: string | null;
  id_degree_course: string | null;
  social_login_origin: string | null;
  website?: string | null;
}

//#region typeguards

export const isUserProfile = implementing<UserProfile>({
  bio: nullable(isstring),
  city: nullable(isstring),
  country: nullable(isstring),
  company_data: nullable(isstring),
  id: isstring,
  email: isstring,
  first_name: nullable(isstring),
  last_name: nullable(isstring),
  profile_image_url: nullable(isstring),
  resume_url: nullable(isstring),
  username: isstring,
  user_type: TUserType.isUserRole,
  id_university: nullable(isstring),
  id_degree_course: nullable(isstring),
  social_login_origin: nullable(isstring),
});

//#endregion

//#region factories

export const create = (partial: Partial<UserProfile> = {}): UserProfile => ({
  bio: partial.bio ?? null,
  city: partial.city ?? null,
  country: partial.country ?? null,
  company_data: partial.company_data ?? null,
  id: partial.id ?? ``,
  email: partial.email ?? ``,
  first_name: partial.first_name ?? null,
  last_name: partial.last_name ?? null,
  profile_image_url: partial.profile_image_url ?? null,
  resume_url: partial.resume_url ?? null,
  username: partial.username ?? ``,
  user_type: partial.user_type ?? TUserType.student,
  id_university: partial.id_university ?? null,
  id_degree_course: partial.id_degree_course ?? null,
  social_login_origin: partial.social_login_origin ?? null,
  website: partial.website ?? null,
});

//#endregion

//#region mappables

export const mapbio = (user: UserProfile) => user.bio;
export const mapcity = (user: UserProfile) => user.city;
export const mapcountry = (user: UserProfile) => user.country;
export const mapcompany_data = (user: UserProfile) => user.company_data;
export const mapemail = (user: UserProfile) => user.email;
export const mapid = (user: UserProfile) => user.id;
export const mapfirst_name = (user: UserProfile) => user.first_name;
export const maplast_name = (user: UserProfile) => user.last_name;
export const mapprofile_image_url = (user: UserProfile) =>
  user.profile_image_url;
export const mapresume_url = (user: UserProfile) => user.resume_url;
export const mapusername = (user: UserProfile) => user.username;
export const mapid_university = (user: UserProfile) => user.id_university;
export const mapsocial_login_origin = (user: UserProfile) =>
  user.social_login_origin;

export const mapbiosafe = pipe(
  option.fromfunction(mapbio),
  option.unwrapOr(``),
);
export const mapcitysafe = pipe(
  option.fromfunction(mapcity),
  option.unwrapOr(``),
);
export const mapcompany_datasafe = pipe(
  option.fromfunction(mapcompany_data),
  option.unwrapOr(``),
);
export const mapfirst_namesafe = pipe(
  option.fromfunction(mapfirst_name),
  option.unwrapOr(``),
);
export const maplast_namesafe = pipe(
  option.fromfunction(maplast_name),
  option.unwrapOr(``),
);
export const mapprofile_image_urlsafe = pipe(
  option.fromfunction(mapprofile_image_url),
  option.unwrapOr(``),
);
export const mapresume_urlsafe = pipe(
  option.fromfunction(mapresume_url),
  option.unwrapOr(``),
);

export const mapfullname = pipe(
  array.fromfunctions(mapfirst_namesafe, maplast_namesafe),
  array.join(' '),
);

export const mapProfileURL = pipe(
  mapusername,
  (username: string) => `/u/${username}`,
);

//#endregion

//#region predicates

export const hasbio = pipe(mapbio, isstring);
export const hascity = pipe(mapcity, isstring);
export const hascompany_data = pipe(mapcompany_data, isstring);
export const hasfirst_name = pipe(mapfirst_name, isstring);
export const haslast_name = pipe(maplast_name, isstring);
export const hasprofile_image_url = pipe(mapprofile_image_url, isstring);
export const hasresume_url = pipe(mapresume_url, isstring);
export const isregisteredwithsocial = pipe(
  mapsocial_login_origin,
  Boolean,
  predicate.withsamevalue(true),
);

//#endregion
