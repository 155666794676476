import { Image } from '@chakra-ui/image';
import { Circle, SquareProps } from '@chakra-ui/react';
import Avatar from 'boring-avatars';
import React, { FC } from 'react';
import { usePalette } from '../usePalette';

export interface UserAvatarProps extends SquareProps {
  avatar?: string;
}

export const UserAvatar: FC<UserAvatarProps> = ({ avatar, ...props }) => {
  const primary = usePalette('primary', true);
  const secondary = usePalette('secondary', true);

  return (
    <Circle
      boxShadow="md"
      size="20"
      overflow="hidden"
      marginBottom="4"
      {...props}
    >
      {avatar && (
        <Image
          height="full"
          objectFit="cover"
          objectPosition="center"
          src={avatar}
          width="full"
        />
      )}
      {!avatar && (
        <Avatar
          size={200}
          variant="beam"
          colors={[
            secondary[300],
            primary[300],
            secondary[400],
            secondary[100],
            primary[500],
          ]}
        />
      )}
    </Circle>
  );
};
