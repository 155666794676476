import { ColorHues, useColorModeValue } from '@chakra-ui/react';

export const usePalette = (
  name: string,
  asCssColor: boolean = false,
): ColorHues => {
  if (!asCssColor) {
    return {
      '50': useColorModeValue(`${name}.50`, `${name}.900`),
      '100': useColorModeValue(`${name}.100`, `${name}.800`),
      '200': useColorModeValue(`${name}.200`, `${name}.700`),
      '300': useColorModeValue(`${name}.300`, `${name}.600`),
      '400': useColorModeValue(`${name}.400`, `${name}.500`),
      '500': useColorModeValue(`${name}.500`, `${name}.400`),
      '600': useColorModeValue(`${name}.600`, `${name}.300`),
      '700': useColorModeValue(`${name}.700`, `${name}.200`),
      '800': useColorModeValue(`${name}.800`, `${name}.100`),
      '900': useColorModeValue(`${name}.900`, `${name}.50`),
    };
  }

  return {
    '50': useColorModeValue(
      `var(--chakra-colors-${name}-50)`,
      `var(--chakra-colors-${name}-900)`,
    ),
    '100': useColorModeValue(
      `var(--chakra-colors-${name}-100)`,
      `var(--chakra-colors-${name}-800)`,
    ),
    '200': useColorModeValue(
      `var(--chakra-colors-${name}-200)`,
      `var(--chakra-colors-${name}-700)`,
    ),
    '300': useColorModeValue(
      `var(--chakra-colors-${name}-300)`,
      `var(--chakra-colors-${name}-600)`,
    ),
    '400': useColorModeValue(
      `var(--chakra-colors-${name}-400)`,
      `var(--chakra-colors-${name}-500)`,
    ),
    '500': useColorModeValue(
      `var(--chakra-colors-${name}-500)`,
      `var(--chakra-colors-${name}-400)`,
    ),
    '600': useColorModeValue(
      `var(--chakra-colors-${name}-600)`,
      `var(--chakra-colors-${name}-300)`,
    ),
    '700': useColorModeValue(
      `var(--chakra-colors-${name}-700)`,
      `var(--chakra-colors-${name}-200)`,
    ),
    '800': useColorModeValue(
      `var(--chakra-colors-${name}-800)`,
      `var(--chakra-colors-${name}-100)`,
    ),
    '900': useColorModeValue(
      `var(--chakra-colors-${name}-900)`,
      `var(--chakra-colors-${name}-50)`,
    ),
  };
};
