import { Select } from '@chakra-ui/select';
import React, { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { FnUnary, pass } from 'tiinvo';
import { create, Paginated } from 'types/Paginated';

export interface PaginationLimitSelectProps {
  paginated: Paginated;
  onLimitChange?: FnUnary<Paginated, any>;
}

//#region i18n

const messages = defineMessages({
  itemsperpage: {
    defaultMessage: `{0} elementi per pagina`,
    id: `ui.datavisualization.PaginationLimitSelect`,
  },
});

//#endregion

export const PaginationLimitSelect: FC<PaginationLimitSelectProps> = ({
  paginated,
  onLimitChange = pass,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Select
      onChange={(event) =>
        onLimitChange(
          create({
            ...paginated,
            current: 0,
            limit: Number(event.target.value),
          }),
        )
      }
      size="xs"
      value={paginated.limit}
    >
      {paginated.perpage.map((limit, index) => (
        <option key={index} value={limit}>
          {formatMessage(messages.itemsperpage, { 0: limit })}
        </option>
      ))}
    </Select>
  );
};
