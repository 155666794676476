import { HStack, useStyleConfig } from '@chakra-ui/react';
import { Copyright } from 'app/branding/Copyright';
import { SocialLink } from 'app/ui/social';
import React, { FC } from 'react';
import { FooterProps } from './FooterProps';
import { PrivacyLink } from './PrivacyLink';
import { TermsLink } from './TermsLink';

export const DesktopFooter: FC<FooterProps> = ({ variant }) => {
  const styles = useStyleConfig(
    'AppFooter',
    { variant },
    { isMultiPart: true },
  );

  return (
    <HStack
      align="center"
      justify="space-between"
      padding="4"
      __css={styles.bar}
    >
      <Copyright />
      <HStack>
        <SocialLink IconProps={{ fill: 'currentColor' }} social="facebook" />
        <SocialLink IconProps={{ fill: 'currentColor' }} social="linkedin" />
        <SocialLink IconProps={{ fill: 'currentColor' }} social="google" />
        <SocialLink IconProps={{ fill: 'currentColor' }} social="youtube" />
      </HStack>
      <HStack>
        <PrivacyLink />
        <TermsLink />
      </HStack>
    </HStack>
  );
};
