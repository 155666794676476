import { Text, TextProps } from '@chakra-ui/react';
import React, { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { multi } from 'tiinvo';
import * as TSocial from 'types/Social';

export interface LinkNameProps extends TextProps {
  social: TSocial.Social;
}

//#region i18n

const messages = defineMessages({
  facebook: {
    defaultMessage: `Facebook`,
    id: `ui.social.Link.facebook`,
  },
  linkedin: {
    defaultMessage: `Linkedin`,
    id: `ui.social.Link.linkedin`,
  },
  twitter: {
    defaultMessage: `Twitter`,
    id: `ui.social.Link.twitter`,
  },
  youtube: {
    defaultMessage: `Youtube`,
    id: `ui.social.Link.youtube`,
  },
  google: {
    defaultMessage: `Google`,
    id: `ui.social.Link.google`,
  },
  none: {
    defaultMessage: ``,
    id: `ui.social.Link.none`,
  },
});

//#endregion

const maptochildren = multi(
  messages.none,
  [TSocial.isfacebook, messages.facebook],
  [TSocial.islinkedin, messages.linkedin],
  [TSocial.istwitter, messages.twitter],
  [TSocial.isyoutube, messages.youtube],
);

export const LinkName: FC<LinkNameProps> = ({ social, ...props }) => {
  const { formatMessage } = useIntl();
  const message = maptochildren(social);

  return (
    <Text fontSize="inherit" marginY="0" {...props}>
      {formatMessage(message)}
    </Text>
  );
};
