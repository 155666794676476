import { gql, useQuery } from '@apollo/client';
import React, { FC } from 'react';

export interface UniversityDisplayProps {
  universityId?: string | null;
}

interface University {
  name: string;
}

interface GetUniversityById {
  universities: University;
}

const query = gql`
  query GetUniversityById($universityId: uuid = "") {
    universities(where: { id: { _eq: $universityId } }) {
      name
    }
  }
`;

export const UniversityDisplay: FC<UniversityDisplayProps> = ({
  universityId,
}) => {
  const { data } = useQuery(query, {
    variables: {
      universityId,
    },
  });
  const found = data?.universities[0];

  if (!found) {
    return null;
  }

  return <>{found.name}</>;
};
