import { Box, BoxProps, Heading, Text } from '@chakra-ui/layout';
import React, { FC, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

export interface NoRecordsFoundProps extends Omit<BoxProps, 'title'> {
  icon?: ReactNode;
  message?: ReactNode;
  title?: ReactNode;
}

export const NoRecordsFound: FC<NoRecordsFoundProps> = ({
  icon,
  message = (
    <FormattedMessage
      defaultMessage="Qui non c'è niente"
      id="ui.NoRecordsFound.message"
    />
  ),
  title = (
    <FormattedMessage defaultMessage="Wow" id="ui.NoRecordsFound.title" />
  ),
  children,
  ...boxprops
}) => {
  return (
    <Box textAlign="center" {...boxprops}>
      {icon}
      <Heading fontWeight="bold" marginBottom="0" variant="h4">
        {title}
      </Heading>
      <Text variant="t6">{message}</Text>
      {children}
    </Box>
  );
};
