import React, { FC } from 'react';
import NLink from 'next/link';
import { Link, LinkProps } from '@chakra-ui/react';

export interface FooterLinkProps extends LinkProps {}

export const FooterLink: FC<FooterLinkProps> = ({ href, ...props }) => {
  return (
    <NLink href={href as string} passHref>
      <Link marginY="0" {...props} />
    </NLink>
  );
};
