import { ComponentStyleConfig } from '@chakra-ui/react';

const basetypographyMixin = {
  marginBottom: `4`,
  marginTop: `0`,
};

export default {
  defaultProps: {
    variant: `t4`,
  },
  variants: {
    t1: {
      fontSize: `4xl`,
      ...basetypographyMixin,
    },
    t2: {
      fontSize: `3xl`,
      ...basetypographyMixin,
    },
    t3: {
      fontSize: `2xl`,
      ...basetypographyMixin,
    },
    t4: {
      color: `purple`,
      fontSize: `xl`,
      fontWeight: '400',
      ...basetypographyMixin,
    },
    t5: {
      fontSize: `lg`,
      ...basetypographyMixin,
    },
    t6: {
      fontSize: `lg`,
      ...basetypographyMixin,
    },
    t7: {
      fontSize: `sm`,
    },
    t8: {
      fontSize: `xs`,
    },
  },
} as ComponentStyleConfig;
