import { Box, Input, List, ListItem } from '@chakra-ui/react';
import {
  useAllDisciplinarySector,
  DisciplinarySector,
} from './useAllDisciplinarySector';
import Fuse from 'fuse.js';
import React, { FC, useEffect, useState } from 'react';
import { FnUnary, pass } from 'tiinvo';
import { useBool, useStr } from 'use-tiinvo';
import { useNeutralPalette } from '../useNeutralPalette';

export interface DisciplinarySectorSelectorProps {
  onChange: FnUnary<string, any>;
  value: string;
}

const config = {
  isCaseSensitive: false,
  keys: ['sector'] as (keyof DisciplinarySector)[],
  // @ts-ignore
} as Fuse.IFuseOptions<DisciplinarySector>;

export const DisciplinarySectorSelector: FC<DisciplinarySectorSelectorProps> =
  ({ onChange = pass, value }) => {
    const allelements = useAllDisciplinarySector();
    const [fuse, setfuse] = useState(new Fuse(allelements, config));
    const [found, setFound] = useState(allelements.find((a) => a.id === value));
    const term = useStr(found?.sector ?? ``);
    const items = term.value
      ? fuse.search(term.value).map((a) => a.item)
      : allelements;
    const selecting = useBool();
    const neutrals = useNeutralPalette();

    useEffect(() => {
      setfuse(new Fuse(allelements, config));
    }, [allelements.length]);

    useEffect(() => {
      const found = allelements.find((a) => a.id === value);
      setFound(found);
      term.set(found?.sector ?? ``);
      selecting.setfalse();
    }, [value, JSON.stringify(allelements)]);

    return (
      <Box position="relative">
        <Input
          onChange={(e) => {
            term.set(e.target.value);
            selecting.settrue();
          }}
          onFocus={selecting.settrue}
          value={term.value}
        />
        {selecting.value && (
          <List
            height="32"
            left="0"
            overflowX="visible"
            overflowY="scroll"
            position="absolute"
            top="full"
            width="full"
            zIndex="modal"
          >
            {items.map((element: DisciplinarySector, index: number) => (
              <ListItem
                backgroundColor={neutrals[50]}
                cursor="pointer"
                px="2"
                py="2"
                onClick={() => {
                  onChange(element?.id);
                  selecting.setfalse();
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    onChange(element?.id);
                    selecting.setfalse();
                  }
                }}
                key={element?.id}
                tabIndex={0}
              >
                {element?.sector}
              </ListItem>
            ))}
          </List>
        )}
      </Box>
    );
  };
