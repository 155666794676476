import { ListItem } from '@chakra-ui/layout';
import React, { FC } from 'react';
import { useNeutralPalette } from '../useNeutralPalette';

export interface ResultListItemProps {
  onClick?: React.MouseEventHandler<HTMLLIElement>;
  onKeyPress?: React.KeyboardEventHandler<HTMLLIElement>;
}

export const ResultListItem: FC<ResultListItemProps> = ({
  children,
  onClick,
  onKeyPress,
}) => {
  const neutrals = useNeutralPalette();

  return (
    <ListItem
      backgroundColor={neutrals[50]}
      cursor="pointer"
      px="2"
      py="2"
      onClick={onClick}
      tabIndex={0}
      onKeyPress={onKeyPress}
    >
      {children}
    </ListItem>
  );
};
