import { Box, Input } from '@chakra-ui/react';
import { useAllActiveUniversities } from '@hasura/hooks/useAllActiveUniversities';
import { University } from '@typings/University';
import Fuse from 'fuse.js';
import React, { FC, useEffect, useState } from 'react';
import { FnUnary, pass } from 'tiinvo';
import { useBool, useStr } from 'use-tiinvo';
import { ResultListItem } from '../listpicker';
import { ResultList } from '../listpicker/ResultList';

export interface UniversityPickerProps {
  onChange: FnUnary<string, any>;
  value: string;
}

const config = {
  isCaseSensitive: false,
  keys: [
    'area',
    'city',
    'name',
    'operational_name',
    'university_code',
  ] as (keyof University)[],
  // @ts-ignore
} as Fuse.IFuseOptions<University>;

export const UniversityPicker: FC<UniversityPickerProps> = ({
  onChange = pass,
  value,
}) => {
  const { universities } = useAllActiveUniversities();
  const [fuse, setfuse] = useState(new Fuse(universities, config));
  const [found, setFound] = useState(universities.find((a) => a.id === value));
  const term = useStr(found?.name ?? ``);
  const items = term.value
    ? fuse.search(term.value).map((a) => a.item)
    : universities;
  const selecting = useBool();

  useEffect(() => {
    setfuse(new Fuse(universities, config));
  }, [universities.length]);

  useEffect(() => {
    const foundUniversity = universities.find((a) => a.id === value);
    setFound(foundUniversity);
    term.set(foundUniversity?.name ?? ``);
    selecting.setfalse();
  }, [value, JSON.stringify(universities)]);

  return (
    <Box>
      <Input
        onChange={(e) => {
          term.set(e.target.value);
          selecting.settrue();
        }}
        onFocus={selecting.settrue}
        value={term.value}
      />
      {selecting.value && (
        <ResultList>
          {items.map((university) => (
            <ResultListItem
              onClick={() => {
                onChange(university.id);
                selecting.setfalse();
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  onChange(university.id);
                  selecting.setfalse();
                }
              }}
            >
              {university.name}
            </ResultListItem>
          ))}
        </ResultList>
      )}
    </Box>
  );
};
