import { Box, List } from '@chakra-ui/react';
import React, { FC } from 'react';

export interface ResultListProps {}

export const ResultList: FC<ResultListProps> = ({ children }) => {
  return (
    <Box pos="relative">
      <List
        height="32"
        left="0"
        overflowX="visible"
        overflowY="scroll"
        position="absolute"
        shadow="lg"
        top="full"
        width="full"
        zIndex="modal"
      >
        {children}
      </List>
    </Box>
  );
};
