import { Text } from '@chakra-ui/layout';
import Link from 'next/link';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Activity, ActivityProps } from './Activity';

export interface FollowedProps extends ActivityProps {}

export const Followed: FC<FollowedProps> = ({ activity }) => {
  return (
    <Activity activity={activity}>
      <FormattedMessage
        defaultMessage="Ha iniziato a seguire {0}"
        id="pages.laura.u.Followed"
        values={{
          0: (
            <Link
              href={`/u/${activity.users_usersTousers_follow_id_user_following.username}`}
              passHref
            >
              <Text as="a" fontSize="sm" color="blue.500">
                {
                  activity.users_usersTousers_follow_id_user_following
                    .first_name
                }{' '}
                {activity.users_usersTousers_follow_id_user_following.last_name}
              </Text>
            </Link>
          ),
        }}
      />
    </Activity>
  );
};
