import { HStack, Text } from '@chakra-ui/layout';
import { Tooltip } from '@chakra-ui/tooltip';
import { useReactionActions } from 'app/catalog/hooks/useReactionActions';
import React, { FC } from 'react';
import { isnullOrUndefined } from 'tiinvo';
import { ReactionsProps } from './Reactions';

export const UnauthUserReactions: FC<ReactionsProps> = ({ contentId }) => {
  const { reactions } = useReactionActions(contentId);

  if (isnullOrUndefined(reactions)) {
    return null;
  }

  return (
    <HStack spacing="3">
      {reactions.reactions.map((reaction, index) => (
        <Tooltip title={reaction.name} key={index}>
          <HStack spacing="1">
            <Text fontSize="sm" margin="0">
              {reaction.emoji}
            </Text>
            <Text fontSize="xs" margin="0">
              {Math.min(Number(reaction.number), 99)}
            </Text>
          </HStack>
        </Tooltip>
      ))}
    </HStack>
  );
};
