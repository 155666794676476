import { ImageProps } from '@chakra-ui/react';
import { useTenant } from 'app/TenantProvider';
import React, { FC } from 'react';
import { fallback, multi } from 'tiinvo';
import * as TTenant from 'types/Tenant';
import { LogoLaura } from './laura/Logo';
import { LogoSofia } from './sofia/Logo';

export interface LogoProps extends Omit<ImageProps, 'src'> {}

const maptenanttoComponent = multi(
  fallback(LogoLaura),
  [TTenant.islaura, fallback(LogoLaura)],
  [TTenant.issofia, fallback(LogoSofia)],
);

export const Logo: FC<LogoProps> = ({ width = '48', ...props }) => {
  const tenant = useTenant();
  const Component = maptenanttoComponent(tenant);

  return <Component width={width} {...props} />;
};
