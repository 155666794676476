import { ComponentStyleConfig } from '@chakra-ui/react';

const basetypographyMixin = {
  marginBottom: `4`,
  marginTop: `3`,
};

const smallerheadingMixin = {
  fontWeight: `300`,
};

export default {
  defaultProps: {
    variant: `h4`,
  },
  variants: {
    h1: {
      fontSize: `4xl`,
      fontWeight: '900',
      ...basetypographyMixin,
    },
    h2: {
      fontSize: `3xl`,
      fontWeight: '700',
      ...basetypographyMixin,
    },
    h3: {
      fontSize: `2xl`,
      fontWeight: '500',
      ...basetypographyMixin,
    },
    h4: {
      fontSize: `xl`,
      fontWeight: '400',
      ...basetypographyMixin,
    },
    h5: {
      fontSize: `lg`,
      ...basetypographyMixin,
      ...smallerheadingMixin,
    },
    h6: {
      fontSize: `lg`,
      ...basetypographyMixin,
      ...smallerheadingMixin,
    },
    h7: {
      fontSize: `sm`,
      ...basetypographyMixin,
      ...smallerheadingMixin,
    },
    h8: {
      fontSize: `xs`,
      ...basetypographyMixin,
      ...smallerheadingMixin,
    },
  },
} as ComponentStyleConfig;
