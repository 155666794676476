import { anyof, isexact } from 'tiinvo';

export const laura = `laura`;
export const sofia = `sofia`;

export type laura = typeof laura;
export type sofia = typeof sofia;

export type Tenant = laura | sofia;

//#region typeguards

export const islaura = isexact<laura>(laura);
export const issofia = isexact<sofia>(sofia);
export const isTenant = anyof<Tenant>(islaura, issofia);

//#endregion
