import { University } from 'types/University';
import gql from 'graphql-tag';

export interface GetAllActiveUniversities {
  universities: University[];
}

// todo: removes (where: { active: { _eq: true } }) because it's not working
const GET_ALL_ACTIVE_UNIVERSITIES = gql`
  query GetAllActiveUniversities {
    universities {
      area
      city
      country
      country_zone
      denomination
      free_state_university
      id
      name
      operational_name
      university_code
      university_type_description
    }
  }
`;

export default GET_ALL_ACTIVE_UNIVERSITIES;
