import { gql, useQuery } from '@apollo/client';
import React, { FC } from 'react';

export interface BusinessDetailDisplayProps {
  idBusinessDetail?: string;
}

interface BusinessDetail {
  business_macro_sector: string;
  id: string;
}

interface GetAllBusinessDetail {
  business_detail: BusinessDetail[];
}

const query = gql`
  query GetAllBusinessDetail {
    business_detail(order_by: { business_macro_sector: asc }) {
      business_macro_sector
      id
    }
  }
`;

export const BusinessDetailDisplay: FC<BusinessDetailDisplayProps> = ({
  idBusinessDetail,
}) => {
  const { data } = useQuery<GetAllBusinessDetail>(query);
  const datasafe = data?.business_detail ?? [];
  const found = datasafe.find((a) => a.id === idBusinessDetail);

  return <>{found?.business_macro_sector}</>;
};
