import { Text } from '@chakra-ui/layout';
import React, { FC } from 'react';
import { UserRoleName } from './UserRoleName';

export interface UserRoleProps {
  role: string;
}

export const UserRole: FC<UserRoleProps> = ({ role }) => {
  return (
    <Text
      fontSize="x-small"
      fontWeight="black"
      opacity=".54"
      textOverflow="ellipsis"
      textTransform="capitalize"
      variant="t8"
      whiteSpace="nowrap"
    >
      <UserRoleName role={role} />
    </Text>
  );
};
