import { Box, Input, ListItem } from '@chakra-ui/react';
import { DegreeCourse } from '@hasura/queries/query/degreecourses/GetAllDegreeCourses';
import { useAllDegreeCourses } from 'app/ui/degreecourse/useAllDegreeCourses';
import Fuse from 'fuse.js';
import React, { FC, useEffect, useState } from 'react';
import { FnUnary, pass } from 'tiinvo';
import { useBool, useStr } from 'use-tiinvo';
import { ResultListItem } from '../listpicker';
import { ResultList } from '../listpicker/ResultList';
import { useNeutralPalette } from '../useNeutralPalette';

export interface DegreeCoursePickerProps {
  onChange: FnUnary<string, any>;
  value: string;
}

const config = {
  isCaseSensitive: false,
  keys: ['title'] as (keyof DegreeCourse)[],
  // @ts-ignore
} as Fuse.IFuseOptions<DegreeCourse>;

export const DegreeCoursePicker: FC<DegreeCoursePickerProps> = ({
  onChange = pass,
  value,
}) => {
  const courses = useAllDegreeCourses();
  const [fuse, setfuse] = useState(new Fuse(courses, config));
  const [found, setFound] = useState(courses.find((a) => a.id === value));
  const term = useStr(found?.title ?? ``);
  const items = term.value
    ? fuse.search(term.value).map((a) => a.item)
    : courses;
  const selecting = useBool();
  const neutrals = useNeutralPalette();

  useEffect(() => {
    setfuse(new Fuse(courses, config));
  }, [courses.length]);

  useEffect(() => {
    const foundUniversity = courses.find((a) => a.id === value);
    setFound(foundUniversity);
    term.set(foundUniversity?.title ?? ``);
    selecting.setfalse();
  }, [value, JSON.stringify(courses)]);

  return (
    <Box>
      <Input
        onChange={(e) => {
          term.set(e.target.value);
          selecting.settrue();
        }}
        onFocus={selecting.settrue}
        value={term.value}
      />
      {selecting.value && (
        <ResultList>
          {items.map((course) => (
            <ResultListItem
              onClick={() => {
                onChange(course.id);
                selecting.setfalse();
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  onChange(course.id);
                  selecting.setfalse();
                }
              }}
              key={course.id}
            >
              {course.title}
            </ResultListItem>
          ))}
        </ResultList>
      )}
    </Box>
  );
};
