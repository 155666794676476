import { Flex } from '@chakra-ui/react';
import { islaura, issofia } from 'types/Tenant';
import { Logo } from 'app/branding/Logo';
import { useTenant } from 'app/TenantProvider';
import React, { FC } from 'react';
import { fallback, multi } from 'tiinvo';
import { Footer } from './footer';
import { Header } from './header';
import { useAuth } from 'app/AuthProvider';

const maptenantsignin = multi(
  fallback(``),
  [islaura, `/auth/sign-in`],
  [issofia, `/auth/sign-in`],
);

const maptenantsignup = multi(
  fallback(``),
  [islaura, `/auth/sign-up`],
  [issofia, `/auth/sign-up`],
);

const maptenantprofile = multi(
  fallback(``),
  [islaura, `/my`],
  [issofia, `/my`],
);

export const Layout: FC = ({ children }) => {
  const tenant = useTenant();
  const auth = useAuth();

  return (
    <Flex direction="column" justifyContent="flex-start" minHeight="100vh">
      <Header
        LogoComponent={Logo}
        myprofileHref={maptenantprofile(tenant)}
        signInHref={maptenantsignin(tenant)}
        signUpHref={maptenantsignup(tenant)}
        variant="fill"
        isLoggedIn={auth.isLoggedIn}
      />
      {children}
      <Footer />
    </Flex>
  );
};
