import { ColorHues, useColorModeValue } from '@chakra-ui/react';

export const useNeutralPalette = (): ColorHues => {
  return {
    '50': useColorModeValue(`gray.50`, `gray.900`),
    '100': useColorModeValue(`gray.100`, `gray.800`),
    '200': useColorModeValue(`gray.200`, `gray.700`),
    '300': useColorModeValue(`gray.300`, `gray.600`),
    '400': useColorModeValue(`gray.400`, `gray.500`),
    '500': useColorModeValue(`gray.500`, `gray.400`),
    '600': useColorModeValue(`gray.600`, `gray.300`),
    '700': useColorModeValue(`gray.700`, `gray.200`),
    '800': useColorModeValue(`gray.800`, `gray.100`),
    '900': useColorModeValue(`gray.900`, `gray.50`),
  };
};
