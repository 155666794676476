import { Heading, HeadingProps } from '@chakra-ui/react';
import React, { FC } from 'react';

export interface SubtitleProps extends HeadingProps {
  haschildrenbelow?: boolean;
}

export const Subtitle: FC<SubtitleProps> = ({ haschildrenbelow, ...props }) => {
  return (
    <Heading
      as="div"
      marginTop="0"
      marginBottom={haschildrenbelow ? '10' : undefined}
      variant="h4"
      textColor="white"
      {...props}
    />
  );
};
