import { useQuery } from '@apollo/client';
import { Box, Input } from '@chakra-ui/react';
import Fuse from 'fuse.js';
import gql from 'graphql-tag';
import React, { FC, useEffect, useState } from 'react';
import { FnUnary, pass } from 'tiinvo';
import { useBool, useStr } from 'use-tiinvo';
import { ResultListItem } from '../listpicker';
import { ResultList } from '../listpicker/ResultList';
import { useNeutralPalette } from '../useNeutralPalette';

export interface BusinessDetailPickerProps {
  onChange: FnUnary<string, any>;
  value: string;
}

interface BusinessDetail {
  business_macro_sector: string;
  id: string;
}

interface GetAllBusinessDetail {
  business_detail: BusinessDetail[];
}

const query = gql`
  query GetAllBusinessDetail {
    business_detail(order_by: { business_macro_sector: asc }) {
      business_macro_sector
      id
    }
  }
`;

const config = {
  isCaseSensitive: false,
  keys: ['business_macro_sector'] as (keyof BusinessDetail)[],
  // @ts-ignore
} as Fuse.IFuseOptions<BusinessDetail>;

export const BusinessDetailPicker: FC<BusinessDetailPickerProps> = ({
  onChange = pass,
  value,
}) => {
  const result = useQuery<GetAllBusinessDetail>(query);
  const courses = result.data?.business_detail ?? [];
  const [fuse, setfuse] = useState(new Fuse(courses, config));
  const [found, setFound] = useState(courses.find((a) => a.id === value));
  const term = useStr(found?.business_macro_sector ?? ``);
  const items = term.value
    ? fuse.search(term.value).map((a) => a.item)
    : courses;
  const selecting = useBool();
  const neutrals = useNeutralPalette();

  useEffect(() => {
    setfuse(new Fuse(courses, config));
  }, [courses.length]);

  useEffect(() => {
    const foundUniversity = courses.find((a) => a.id === value);
    setFound(foundUniversity);
    term.set(foundUniversity?.business_macro_sector ?? ``);
    selecting.setfalse();
  }, [value, JSON.stringify(courses)]);

  return (
    <Box>
      <Input
        onChange={(e) => {
          term.set(e.target.value);
          selecting.settrue();
        }}
        onFocus={selecting.settrue}
        value={term.value}
      />
      {selecting.value && (
        <ResultList>
          {items.map((course) => (
            <ResultListItem
              onClick={() => {
                onChange(course.id);
                selecting.setfalse();
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  onChange(course.id);
                  selecting.setfalse();
                }
              }}
              key={course.id}
            >
              {course.business_macro_sector}
            </ResultListItem>
          ))}
        </ResultList>
      )}
    </Box>
  );
};
