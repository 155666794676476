import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, HStack } from '@chakra-ui/react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { useUserProfile } from '@hasura/hooks/useUserProfile';
import { useFileDownload } from 'app/useFileDownload';
import { useFormik } from 'formik';
import React, { FC, useEffect } from 'react';
import { BiUpload } from 'react-icons/bi';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import { BusinessDetailPicker, FileSelector } from '..';
import { CitySelector } from '../city';
import { ProfileDataUpdateActions } from '../form/ProfileDataUpdateActions';
import { WebsiteInput } from '../form/WebsiteInput';
import { UserProfileEditableFormProps } from '../UserProfileEditableFormProps';

interface CompanyData {
  business_name: string;
  city: string | null;
  description: string | null;
  id: string;
  id_business_detail: string;
  legal_entity: string;
  website: string | null;
}

const makeCompanyData = (): CompanyData => ({
  business_name: ``,
  city: ``,
  description: ``,
  id: ``,
  id_business_detail: ``,
  legal_entity: ``,
  website: ``,
});

interface GetCompanyData {
  company_data: CompanyData[];
}

const query = gql`
  query GetCompanyData($user_id: uuid = "") {
    company_data(where: { id_user: { _eq: $user_id } }) {
      business_name
      city
      description
      id
      id_business_detail
      legal_entity
      website
    }
  }
`;

const usermutation = gql`
  mutation updateUserProfile(
    $first_name: String
    $last_name: String
    $website: String
    $resume_url: String
    $uuid: uuid
  ) {
    update_users(
      where: { id: { _eq: $uuid } }
      _set: {
        first_name: $first_name
        last_name: $last_name
        website: $website
        resume_url: $resume_url
      }
    ) {
      affected_rows
    }
  }
`;

const companydatamutation = gql`
  mutation updateCompanyData(
    $id: uuid = ""
    $business_name: String = ""
    $id_business_detail: uuid = ""
    $website: String = ""
    $description: String = ""
    $city: String = ""
  ) {
    update_company_data(
      where: { id: { _eq: $id } }
      _set: {
        business_name: $business_name
        id_business_detail: $id_business_detail
        website: $website
        description: $description
        city: $city
      }
    ) {
      affected_rows
    }
  }
`;

export const CompanyDataFormEdit: FC<UserProfileEditableFormProps> = ({
  uuid,
  onSave,
  onUploadResume,
  canmodifyresume,
  disabled,
  isuploadingresume,
}) => {
  const companydata =
    useQuery<GetCompanyData>(query, { variables: { user_id: uuid } }).data
      ?.company_data[0] ?? makeCompanyData();
  const [mutateuserprofile, mutateuserprofilestate] = useMutation(usermutation);
  const [mutatecompanydata, mutatecompanydatastate] =
    useMutation(companydatamutation);
  const user = useUserProfile(uuid).userprofile;
  const form = useFormik({
    initialValues: {
      user,
      companydata,
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      user: yup.object({
        website: yup
          .string()
          .url(`Una URL valida è per esempio https://www.google.com`)
          .notRequired()
          .nullable(),
      }),
    }),
    onSubmit: ({ companydata, user }) => {
      const { first_name, last_name, resume_url, website } = user;
      const { business_name, city, description, id, id_business_detail } =
        companydata;

      mutateuserprofile({
        variables: {
          first_name,
          last_name,
          resume_url,
          website,
        },
      });
      mutatecompanydata({
        variables: {
          business_name,
          city,
          description,
          id,
          id_business_detail,
        },
      });
    },
  });

  useEffect(() => {
    if (
      mutateuserprofilestate.data &&
      mutatecompanydatastate.data &&
      !mutateuserprofilestate.loading &&
      !mutatecompanydatastate.loading
    ) {
      onSave && onSave(true);
      form.resetForm({
        values: form.values,
      });
    }
  }, [
    mutateuserprofilestate.data,
    mutatecompanydatastate.data,
    mutateuserprofilestate.loading && mutatecompanydatastate.loading,
  ]);

  const handledownload = useFileDownload(form.values.user.resume_url);

  return (
    <>
      <FormControl isInvalid={!!form.errors.user?.first_name}>
        <FormLabel>
          <FormattedMessage
            defaultMessage="Nome"
            id="ui.professor.ProfessorDataForm.user.first_name"
          />
        </FormLabel>
        <Input
          name="user.first_name"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.user.first_name ?? ``}
        />
        <FormErrorMessage>{form.errors.user?.first_name}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!form.errors.user?.last_name}>
        <FormLabel>
          <FormattedMessage
            defaultMessage="Cognome"
            id="ui.professor.ProfessorDataForm.last_name"
          />
        </FormLabel>
        <Input
          name="user.last_name"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.user.last_name ?? ``}
        />
        <FormErrorMessage>{form.errors.user?.last_name}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!form.errors.companydata?.business_name}>
        <FormLabel>
          <FormattedMessage
            id="ui.company.CompanyDataForm.business_name"
            defaultMessage="Denominazione Azienda"
          />
        </FormLabel>
        <Input
          name="companydata.business_name"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.companydata?.business_name ?? ``}
        />
        <FormErrorMessage>
          {form.errors.companydata?.business_name}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!form.errors.companydata?.description}>
        <FormLabel>
          <FormattedMessage
            id="ui.company.CompanyDataForm.description"
            defaultMessage="Descrizione Azienda"
          />
        </FormLabel>
        <Input
          name="companydata.description"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.companydata?.description ?? ``}
        />
        <FormErrorMessage>
          {form.errors.companydata?.description}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!form.errors.companydata?.city}>
        <FormLabel>
          <FormattedMessage
            id="ui.company.CompanyDataForm.city"
            defaultMessage="Città"
          />
        </FormLabel>
        <CitySelector
          onChange={(city) => {
            form.setFieldValue('companydata.city', city, true);
            form.setFieldTouched('companydata.city', true, true);
          }}
          value={form.values.companydata?.city ?? ``}
        />
        <FormErrorMessage>{form.errors.companydata?.city}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!form.errors.companydata?.id_business_detail}>
        <FormLabel>
          <FormattedMessage
            id="ui.company.CompanyDataForm.id_business_detail"
            defaultMessage="Settore economico"
          />
        </FormLabel>
        <BusinessDetailPicker
          onChange={(value) => {
            form.setFieldValue('companydata.id_business_detail', value, true);
            form.setFieldTouched('companydata.id_business_detail', true, true);
          }}
          value={form.values.companydata?.id_business_detail ?? ``}
        />
        <FormErrorMessage>
          {form.errors.companydata?.id_business_detail}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!form.values.user?.website}>
        <FormLabel>
          <FormattedMessage
            id="ui.professor.CompanyData.website"
            defaultMessage="Sito web"
          />
        </FormLabel>
        <WebsiteInput
          name="user.website"
          onBlur={form.handleBlur}
          onChange={form.handleChange}
          value={form.values.user?.website ?? ``}
        />
        <FormErrorMessage>{form.errors.user?.website}</FormErrorMessage>
      </FormControl>

      <HStack
        alignItems="center"
        justifyContent="center"
        spacing="4"
        width="full"
      >
        {canmodifyresume && (
          <FileSelector
            accepts=".pdf"
            colorScheme="primary"
            isLoading={isuploadingresume}
            leftIcon={<BiUpload />}
            onSelect={(files) => onUploadResume && form.submitForm().then(() => onUploadResume(files[0]))}
            size="sm"
            variant="solid"
          >
            {!!form.values.user.resume_url && (
              <FormattedMessage
                defaultMessage="Aggiorna Curriculum"
                id="ui.user.UserProfile.edit.resume_url.update"
              />
            )}
            {!form.values.user.resume_url && (
              <FormattedMessage
                defaultMessage="Carica Curriculum"
                id="ui.user.UserProfile.edit.resume_url.upload"
              />
            )}
          </FileSelector>
        )}
        {!!form.values.user.resume_url && (
          <>
            <Button
              onClick={handledownload}
              marginTop="4"
              textDecoration="underline"
              size="sm"
              variant="link"
            >
              <FormattedMessage
                defaultMessage="Scarica Curriculum"
                id="ui.user.UserProfile.edit.resume_url.download"
              />
            </Button>
          </>
        )}
      </HStack>

      <ProfileDataUpdateActions disabled={disabled} form={form} />
    </>
  );
};
