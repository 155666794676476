import { Button, HStack } from '@chakra-ui/react';
import React, { FC } from 'react';
import { bind, FnUnary, pass, predicate } from 'tiinvo';
import * as TPaginated from 'types/Paginated';

export interface PaginationProps {
  paginated: TPaginated.Paginated<any[]>;
  onPageChange?: FnUnary<TPaginated.Paginated<any[]>, any>;
}

const avoidrender = predicate.or(
  TPaginated.isempty,
  predicate.reverse(TPaginated.haspages),
  TPaginated.hasonepage,
);

const createpagesarray = (num: number) =>
  Array.from(new Array(num)).map((_, index) => index);

export const Pagination: FC<PaginationProps> = ({
  onPageChange = pass,
  paginated,
}) => {
  if (avoidrender(paginated)) {
    return null;
  }

  const iscurrent = predicate.withsamevalue(paginated.current);
  const pagesarray = createpagesarray(paginated.pages);

  return (
    <HStack spacing="2">
      {pagesarray.map((_, index) => (
        <Button
          key={index}
          _focus={{
            outline: `0 none`,
          }}
          colorScheme={iscurrent(index) ? 'primary' : 'gray'}
          cursor={iscurrent(index) ? 'default' : 'pointer'}
          onClick={bind(onPageChange, { ...paginated, current: index })}
          size="xs"
          variant="solid"
        >
          {index + 1}
        </Button>
      ))}
    </HStack>
  );
};
