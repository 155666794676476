import { Flex } from '@chakra-ui/react';
import React, { FC } from 'react';
import { useNeutralPalette } from '../useNeutralPalette';

export interface PromptLayoutProps {}

export const PromptLayout: FC<PromptLayoutProps> = ({ children }) => {
  const gray = useNeutralPalette();

  return (
    <Flex
      alignItems="center"
      backgroundColor={gray[200]}
      direction="column"
      justifyContent="center"
      minHeight="100vh"
    >
      {children}
    </Flex>
  );
};
