import { gql } from '@apollo/client';
import { ProfessorDetail } from 'types/professor/ProfessorDetail';

export interface getAllProfessorDetail {
  professor_detail: ProfessorDetail[];
}

const GET_ALL_PROFESSOR_DETAIL = gql`
  query getAllProfessorDetail {
    professor_detail {
      disciplinary_area
      disciplinary_area_desc
      disciplinary_area_title
      id
    }
  }
`;

export default GET_ALL_PROFESSOR_DETAIL;
