import {
  Button,
  HStack,
  Link as CLink,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useStyleConfig,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { HeaderProps } from './HeaderProps';
import { GiHamburgerMenu, GiPerson } from 'react-icons/gi';
import { FormattedMessage } from 'react-intl';
import { useRouter } from 'next/router';
import { bind } from 'tiinvo';
import Link from 'next/link';

export const MobileHeader: FC<HeaderProps> = ({
  LogoComponent,
  signInHref,
  signUpHref,
  myprofileHref,
  isLoggedIn,
  variant,
}) => {
  const router = useRouter();
  const handlesignin = bind(router.push, signInHref);
  const handlesignup = bind(router.push, signUpHref);
  const styles = useStyleConfig(
    'AppHeader',
    { variant },
    { isMultiPart: true },
  );

  return (
    <HStack
      __css={styles.bar}
      justify="space-between"
      paddingX="4"
      paddingY="2"
      position="fixed"
      top="0"
      left="0"
      width="full"
      zIndex="10000000"
    >
      <Link href="/">
        <LogoComponent width="24" />
      </Link>
      {isLoggedIn && (
        <Link href={myprofileHref} passHref>
          <CLink>
            <GiPerson />
            <FormattedMessage
              defaultMessage="Il mio profilo"
              id="ui.layout.header.myprofile"
            />
          </CLink>
        </Link>
      )}
      {!isLoggedIn && (
        <Menu>
          <MenuButton as={Button} variant="secondaryOutline">
            <GiHamburgerMenu />
          </MenuButton>
          <MenuList color="primary.900">
            <MenuItem onClick={handlesignin}>
              <FormattedMessage
                defaultMessage="Accedi"
                id="ui.layout.header.signin"
              />
            </MenuItem>
            <MenuItem onClick={handlesignup}>
              <FormattedMessage
                defaultMessage="Registrati"
                id="ui.layout.header.signup"
              />
            </MenuItem>
          </MenuList>
        </Menu>
      )}
    </HStack>
  );
};
