import { Center, Container, VStack } from '@chakra-ui/layout';
import React, { FC } from 'react';
import { BiPencil } from 'react-icons/bi';
import { FnUnary, pass } from 'tiinvo';
import * as TUserProfile from 'types/auth/UserProfile';
import * as TUserType from 'types/auth/UserRole';
import { CompanyDataFormEdit } from '../company/CompanyDataFormEdit';
import { FileSelector } from '../form/FileSelector';
import { ProfessorDataFormEdit } from '../professor/ProfessorDataFormEdit';
import { StudentDataFormEdit } from '../student/StudentDataFormEdit';
import { UserAvatar } from './UserAvatar';

export interface UserProfileProps {
  canmodifyavatar?: boolean;
  canmodifyresume?: boolean;
  disabled?: boolean;
  isuploadingavatar?: boolean;
  isuploadingresume?: boolean;
  user: TUserProfile.UserProfile;
  onUploadAvatar?: FnUnary<File, any>;
  onUploadResume?: FnUnary<File, any>;
  onSave?: FnUnary<boolean, any>;
}

export const UserProfile: FC<UserProfileProps> = (props) => {
  const {
    canmodifyavatar = true,
    isuploadingavatar,
    user,
    onUploadAvatar = pass,
    onSave = () => {},
  } = props;

  return (
    <div>
      <Center marginBottom="6" marginX="auto" maxW="32" position="relative">
        <UserAvatar avatar={TUserProfile.mapprofile_image_urlsafe(user)} />
        {canmodifyavatar && (
          <FileSelector
            borderRadius="full"
            bottom="4"
            boxShadow="lg"
            isLoading={isuploadingavatar}
            borderWidth="thin"
            position="absolute"
            right="4"
            size="xs"
            onSelect={(files) => onUploadAvatar(files[0])}
          >
            <BiPencil />
          </FileSelector>
        )}
      </Center>
      <VStack as={Container} maxWidth="container.md" spacing="4">
        {(() => {
          switch (user.user_type) {
            case TUserType.company:
              return (
                <CompanyDataFormEdit
                  {...props}
                  uuid={user.id}
                  onSave={onSave}
                />
              );
            case TUserType.contract_teacher:
            case TUserType.lecteur:
              return (
                <ProfessorDataFormEdit
                  {...props}
                  uuid={user.id}
                  onSave={onSave}
                />
              );
            case TUserType.student:
              return (
                <StudentDataFormEdit
                  {...props}
                  uuid={user.id}
                  onSave={onSave}
                />
              );
            default:
              return null;
          }
        })()}
      </VStack>
    </div>
  );
};
