import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Stack, StackProps, Circle } from '@chakra-ui/layout';
import { Button, SquareProps, TooltipProps } from '@chakra-ui/react';
import { Tooltip } from '@chakra-ui/tooltip';
import React, { FC } from 'react';
import { branch, FnUnary, isboolean, pass, predicate } from 'tiinvo';
import { useNeutralPalette } from '../useNeutralPalette';
import { usePalette } from '../usePalette';

export interface StepCounterProps extends StackProps {
  CircleProps?: SquareProps;
  cangoforward?: boolean;
  cangobackward?: boolean;
  TooltipProps?: Omit<TooltipProps, 'label'>;
  current?: number;
  onClickDirection?: FnUnary<number, any>;
  steps?: string[];
}

export const StepCounter: FC<StepCounterProps> = ({
  CircleProps,
  TooltipProps,
  cangoforward,
  cangobackward,
  current = 0,
  onClickDirection = pass,
  steps = [],
  ...stackprops
}) => {
  const iscurrent = predicate.withsamevalue(current);
  const neutrals = useNeutralPalette();
  const primary = usePalette(`primary`);
  const mapcolor = branch(iscurrent, primary[500], neutrals[300]);

  return (
    <Stack
      alignItems="center"
      direction="row"
      height="10"
      justifyContent="space-between"
      spacing="2"
      {...stackprops}
    >
      <Button
        disabled={isboolean(cangobackward) ? !cangobackward : current === 0}
        colorScheme="gray"
        justifySelf="flex-start"
        onClick={() => onClickDirection(current - 1)}
        size="xs"
        variant="solid"
        width="4"
      >
        <ChevronLeftIcon />
      </Button>
      <Stack direction="row" justify="center" spacing="2">
        {steps.map((step, index) => (
          <Tooltip fontSize="xs" label={step} key={index} {...TooltipProps}>
            <Circle
              backgroundColor={mapcolor(index)}
              size="2"
              {...CircleProps}
            />
          </Tooltip>
        ))}
      </Stack>
      <Button
        disabled={
          isboolean(cangoforward) ? !cangoforward : current >= steps.length
        }
        colorScheme="gray"
        justifySelf="flex-start"
        onClick={() => onClickDirection(current + 1)}
        size="xs"
        variant="solid"
        width="4"
      >
        <ChevronRightIcon />
      </Button>
    </Stack>
  );
};
