import { ComponentStyleConfig } from '@chakra-ui/react';
import * as THeaderVariant from 'types/ui/layout/HeaderVariant';

export default {
  parts: ['bar'],
  defaultProps: {
    variant: THeaderVariant.fill,
  },
  variants: {
    [THeaderVariant.minimal]: ({ colorMode }) => ({
      bar: {
        backgroundColor: colorMode === 'dark' ? 'black' : 'white',
        color: colorMode === 'dark' ? 'primary.500' : 'primary.400',
      },
    }),
    [THeaderVariant.fill]: ({ colorMode }) => ({
      bar: {
        backgroundColor: colorMode === 'dark' ? 'primary.700' : 'primary.900',
        color: colorMode === 'dark' ? 'primary.50' : 'primary.50',
      },
    }),
  },
} as ComponentStyleConfig;
