import { useQuery } from '@apollo/client';
import GET_ALL_ACTIVE_UNIVERSITIES, {
  GetAllActiveUniversities,
} from '@hasura/queries/query/universities/getAllActiveUniversities';

export const useAllActiveUniversities = () => {
  const query = useQuery<GetAllActiveUniversities>(GET_ALL_ACTIVE_UNIVERSITIES);
  const universities = query.data?.universities ?? [];

  return {
    ...query,
    universities,
  };
};
