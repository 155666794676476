import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { BusinessDetailPicker } from '../businessdetail';
import { useCompanyDataForm } from './useCompanyDataForm';

export interface CompanyDataFormProps {
  form: ReturnType<useCompanyDataForm>;
}

export const CompanyDataForm: FC<CompanyDataFormProps> = ({ form }) => {
  return (
    <>
      <FormControl isInvalid={!!form.errors.first_name}>
        <FormLabel>
          <FormattedMessage
            defaultMessage="Nome"
            id="ui.professor.ProfessorDataForm.first_name"
          />
        </FormLabel>
        <Input
          name="first_name"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.first_name ?? ``}
        />
        <FormErrorMessage>{form.errors.first_name}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!form.errors.last_name}>
        <FormLabel>
          <FormattedMessage
            defaultMessage="Cognome"
            id="ui.professor.ProfessorDataForm.last_name"
          />
        </FormLabel>
        <Input
          name="last_name"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.last_name ?? ``}
        />
        <FormErrorMessage>{form.errors.last_name}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!form.errors.business_name}>
        <FormLabel>
          <FormattedMessage
            id="ui.company.CompanyDataForm.business_name"
            defaultMessage="Denominazione Azienda"
          />
        </FormLabel>
        <Input
          name="business_name"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.business_name ?? ``}
        />
        <FormErrorMessage>{form.errors.business_name}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!form.errors.id_business_detail}>
        <FormLabel>
          <FormattedMessage
            id="ui.company.CompanyDataForm.ateco_sector"
            defaultMessage="Settore economico"
          />
        </FormLabel>
        <BusinessDetailPicker
          onChange={(value) => {
            setTimeout(() => {
              form.setFieldValue('id_business_detail', value, true);
              form.setFieldTouched('id_business_detail', true, true);
            }, 10);
          }}
          value={form.values.id_business_detail ?? ``}
        />
        <FormErrorMessage>{form.errors.id_business_detail}</FormErrorMessage>
      </FormControl>
      <HStack marginTop="4">
        <Button onClick={(_) => form.resetForm()} isLoading={form.isSubmitting}>
          <FormattedMessage
            id="ui.company.CompanyDataForm.reset"
            defaultMessage="Reimposta"
          />
        </Button>
        <Button
          disabled={!form.isValid}
          onClick={form.submitForm}
          isLoading={form.isSubmitting}
          colorScheme="blue"
          variant="solid"
        >
          <FormattedMessage
            id="ui.company.CompanyDataForm.submit"
            defaultMessage="Invia"
          />
        </Button>
      </HStack>
    </>
  );
};
