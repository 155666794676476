import {
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useStyleConfig,
} from '@chakra-ui/react';
import { useUserProfileByUsername } from '@hasura/hooks/useUserProfile';
import { useAuth } from 'app/AuthProvider';
import { useNeutralPalette } from 'app/ui/useNeutralPalette';
import { UserAvatar } from 'app/ui/user';
import Link from 'next/link';
import React, { FC, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { HeaderProps } from './HeaderProps';
import { useSafePersonalLinkMap } from './useSafePersonalLinkMap';

export const DesktopHeader: FC<HeaderProps> = ({
  children,
  LogoComponent = Fragment,
  isLoggedIn = false,
  myprofileHref,
  signInHref,
  signUpHref,
  variant,
}) => {
  const styles = useStyleConfig(
    'AppHeader',
    { variant },
    { isMultiPart: true },
  );
  const username = useAuth().user?.username ?? ``;
  const user = useUserProfileByUsername(username);
  const neutrals = useNeutralPalette();
  const remapped = useSafePersonalLinkMap({
    myprofileHref,
    uploads: '/my/uploads',
    favourites: '/my/favourites',
  });

  return (
    <Box
      __css={styles.bar}
      position="sticky"
      top="0"
      width="full"
      zIndex="docked"
    >
      <HStack
        justify="space-between"
        paddingX="4"
        paddingY="1"
        marginX="auto"
        maxW="container.xl"
      >
        <Link href="/">
          <span>
            <LogoComponent cursor="pointer" maxWidth="48" />
          </span>
        </Link>
        {!isLoggedIn && (
          <HStack>
            <Link href={signInHref} passHref>
              <Button
                _hover={{ background: 'transparent' }}
                as="a"
                color="inherit"
                size="sm"
                variant="ghost"
              >
                <FormattedMessage
                  defaultMessage="Accedi"
                  id="ui.layout.header.signin"
                />
              </Button>
            </Link>
            <Link href={signUpHref} passHref>
              <Button as="a" size="sm" variant="secondaryOutline">
                <FormattedMessage
                  defaultMessage="Registrati"
                  id="ui.layout.header.signup"
                />
              </Button>
            </Link>
          </HStack>
        )}
        {isLoggedIn && (
          <Menu isLazy>
            <MenuButton>
              <UserAvatar
                avatar={user.userprofile.profile_image_url ?? undefined}
                margin="0"
                size="12"
              />
            </MenuButton>
            <MenuList color={neutrals[800]} zIndex="9">
              <Link href={remapped.myprofileHref} passHref>
                <MenuItem as="a">
                  <FormattedMessage
                    defaultMessage="Il mio profilo"
                    id="ui.layout.header.myprofile"
                  />
                </MenuItem>
              </Link>
              <Link href={remapped.uploads} passHref>
                <MenuItem as="a">
                  <FormattedMessage
                    defaultMessage="Le mie tesi"
                    id="ui.layout.header.mydocs"
                  />
                </MenuItem>
              </Link>
              <Link href={remapped.favourites} passHref>
                <MenuItem as="a">
                  <FormattedMessage
                    defaultMessage="I miei preferiti"
                    id="ui.layout.header.myfavs"
                  />
                </MenuItem>
              </Link>
              <MenuDivider />
              <Link href="/auth/logout" passHref>
                <MenuItem as="a">
                  <FormattedMessage
                    defaultMessage="Logout"
                    id="ui.layout.header.logout"
                  />
                </MenuItem>
              </Link>
            </MenuList>
          </Menu>
        )}
      </HStack>
    </Box>
  );
};
