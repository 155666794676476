import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';

export interface City {
  name: string;
  id: string;
}

export interface GetAllCities {
  cities: City[];
}

export const GET_ALL_CITIES = gql`
  query GetAllCities {
    cities(order_by: { name: asc }) {
      name
      id
    }
  }
`;

export const useAllCities = () => {
  const query = useQuery<GetAllCities>(GET_ALL_CITIES);
  return query.data?.cities ?? [];
};
