import { UserRole } from 'types/auth/UserRole';
import React, { FC } from 'react';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';

export interface UserRoleNameProps {
  role: string;
}

//#region i18n

const messages = defineMessages({
  'usertype.student': {
    defaultMessage: `Student`,
    id: `usertype.student`,
  },
  'usertype.superuser': {
    defaultMessage: `Superuser`,
    id: `usertype.superuser`,
  },
  'usertype.company': {
    defaultMessage: `Company`,
    id: `usertype.company`,
  },
  'usertype.professional': {
    defaultMessage: `Professional`,
    id: `usertype.professional`,
  },
  'usertype.lecteur': {
    defaultMessage: `Lecteur`,
    id: `usertype.lecteur`,
  },
  'usertype.researcher': {
    defaultMessage: `Researcher`,
    id: `usertype.researcher`,
  },
  'usertype.contract_teacher': {
    defaultMessage: `Contract Teacher`,
    id: `usertype.contract_teacher`,
  },
  'usertype.research_fellow': {
    defaultMessage: `Research Fellow`,
    id: `usertype.research_fellow`,
  },
  'usertype.phd_student': {
    defaultMessage: `Phd Student`,
    id: `usertype.phd_student`,
  },
  'usertype.scolarship_holder': {
    defaultMessage: `Scolarship Holder`,
    id: `usertype.scolarship_holder`,
  },
} as Record<UserRole, MessageDescriptor>);

//#endregion

export const UserRoleName: FC<UserRoleNameProps> = ({ role }) => {
  const { formatMessage } = useIntl();
  const message = (messages as any)[role] ?? undefined;

  if (!message) {
    return null;
  }

  return <>{formatMessage(message)}</>;
};
