import { gql, useMutation } from '@apollo/client';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { useUserProfile } from '@hasura/hooks/useUserProfile';
import { useFileDownload } from 'app/useFileDownload';
import { useFormik } from 'formik';
import React, { FC, useEffect } from 'react';
import { BiUpload, BiWorld } from 'react-icons/bi';
import { FormattedMessage } from 'react-intl';
import * as yup from 'yup';
import { FileSelector, UniversityPicker } from '..';
import { CitySelector } from '../city';
import { DegreeCoursePicker } from '../degreecourse';
import { ProfileDataUpdateActions } from '../form/ProfileDataUpdateActions';
import { WebsiteInput } from '../form/WebsiteInput';
import { UserProfileEditableFormProps } from '../UserProfileEditableFormProps';

const mutation = gql`
  mutation updateUserProfile(
    $bio: String
    $city: String
    $country: String
    $first_name: String
    $id_university: uuid
    $last_name: String
    $resume_url: String
    $uuid: uuid
    $id_degree_course: uuid
    $website: String
  ) {
    update_users(
      where: { id: { _eq: $uuid } }
      _set: {
        bio: $bio
        city: $city
        country: $country
        first_name: $first_name
        id_university: $id_university
        last_name: $last_name
        resume_url: $resume_url
        id_degree_course: $id_degree_course
        website: $website
      }
    ) {
      affected_rows
    }
  }
`;

export const StudentDataFormEdit: FC<UserProfileEditableFormProps> = ({
  uuid,
  onSave,
  onUploadResume,
  canmodifyresume,
  disabled,
  isuploadingresume,
}) => {
  const [update, updatestate] = useMutation(mutation);
  const user = useUserProfile(uuid);
  const form = useFormik({
    enableReinitialize: true,
    initialValues: user.userprofile,
    validationSchema: yup.object({
      bio: yup.string().nullable().optional(),
      city: yup.string().nullable().optional(),
      country: yup.string().nullable().optional(),
      first_name: yup.string().required(`Questo campo è obbligatorio`),
      id_university: yup.string().required(`Questo campo è obbligatorio`),
      last_name: yup.string().required(`Questo campo è obbligatorio`),
      resume_url: yup.string().nullable().optional(),
      id_degree_course: yup.string().required(`Questo campo è obbligatorio`),
      website: yup
        .string()
        .url(`Una URL valida è per esempio https://www.google.com`)
        .notRequired()
        .nullable(),
    }),
    onSubmit: (variables) => {
      const {
        bio,
        city,
        country,
        first_name,
        id_university,
        last_name,
        resume_url,
        id_degree_course,
        website,
      } = variables;

      return update({
        variables: {
          bio,
          city,
          country,
          first_name,
          id_university,
          last_name,
          resume_url,
          id_degree_course,
          website,
        },
      });
    },
  });

  useEffect(() => {
    if (updatestate.data && updatestate.called) {
      form.resetForm({
        values: form.values,
      });
      onSave(true);
    }
  }, [updatestate.called, updatestate.data]);

  const handledownload = useFileDownload(form.values.resume_url);

  return (
    <>
      <FormControl isInvalid={!!form.errors.first_name}>
        <FormLabel>
          <FormattedMessage
            defaultMessage="Nome"
            id="ui.student.StudentDataForm.first_name"
          />
        </FormLabel>
        <Input
          name="first_name"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.first_name ?? ``}
        />
        <FormErrorMessage>{form.errors.first_name}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!form.errors.last_name}>
        <FormLabel>
          <FormattedMessage
            defaultMessage="Cognome"
            id="ui.student.StudentDataForm.last_name"
          />
        </FormLabel>
        <Input
          name="last_name"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.last_name ?? ``}
        />
        <FormErrorMessage>{form.errors.last_name}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!form.errors.id_university}>
        <FormLabel>
          <FormattedMessage
            id="ui.student.StudentData.university"
            defaultMessage="Università"
          />
        </FormLabel>
        <UniversityPicker
          onChange={(id_university) => {
            form.setFieldValue('id_university', id_university, true);
            form.setFieldTouched('id_university', true, true);
          }}
          value={form.values.id_university ?? ``}
        />
        <FormErrorMessage>{form.errors.id_university}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!form.errors.id_degree_course}>
        <FormLabel>
          <FormattedMessage
            id="ui.student.StudentData.id_degree_course"
            defaultMessage="Corso di studi"
          />
        </FormLabel>
        <DegreeCoursePicker
          onChange={(id_degree_course) => {
            form.setFieldValue('id_degree_course', id_degree_course, true);
            form.setFieldTouched('id_degree_course', true, true);
          }}
          value={form.values.id_degree_course ?? ``}
        />
        <FormErrorMessage>{form.errors.id_degree_course}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!form.errors.bio && form.touched.bio}>
        <FormLabel>
          <FormattedMessage
            defaultMessage="Biografia"
            id="ui.user.UserProfile.edit.bio"
          />
        </FormLabel>
        <Textarea
          disabled={disabled}
          maxLength={500}
          name="bio"
          onBlur={form.handleBlur}
          onChange={form.handleChange}
          resize="none"
          value={form.values.bio ?? ''}
        />
        <Text fontSize="xs" textAlign="right">
          <FormattedMessage
            defaultMessage="{0}/{1} caratteri"
            id="ui.user.UserProfile.edit.bio_length"
            values={{
              0: (form.values.bio ?? '').length,
              1: 500,
            }}
          />
        </Text>
      </FormControl>

      <FormControl isInvalid={!!form.errors.website}>
        <FormLabel>
          <FormattedMessage
            id="ui.professor.StudentData.website"
            defaultMessage="Sito web"
          />
        </FormLabel>
        <WebsiteInput
          name="website"
          onBlur={form.handleBlur}
          onChange={form.handleChange}
          value={form.values.website ?? ``}
        />
        <FormErrorMessage>{form.errors.website}</FormErrorMessage>
      </FormControl>

      <HStack
        alignItems="center"
        justifyContent="center"
        spacing="4"
        width="full"
      >
        {canmodifyresume && (
          <FileSelector
            accepts=".pdf"
            colorScheme="primary"
            isLoading={isuploadingresume}
            leftIcon={<BiUpload />}
            onSelect={(files) => onUploadResume && form.submitForm().then(() => onUploadResume(files[0])) }
            size="sm"
            variant="solid"
          >
            {!!form.values.resume_url && (
              <FormattedMessage
                defaultMessage="Aggiorna Curriculum"
                id="ui.user.UserProfile.edit.resume_url.update"
              />
            )}
            {!form.values.resume_url && (
              <FormattedMessage
                defaultMessage="Carica Curriculum"
                id="ui.user.UserProfile.edit.resume_url.upload"
              />
            )}
          </FileSelector>
        )}
        {!!form.values.resume_url && (
          <>
            <Button
              onClick={handledownload}
              marginTop="4"
              textDecoration="underline"
              size="sm"
              variant="link"
            >
              <FormattedMessage
                defaultMessage="Scarica Curriculum"
                id="ui.user.UserProfile.edit.resume_url.download"
              />
            </Button>
          </>
        )}
      </HStack>

      <HStack width="full" spacing="4">
        <FormControl isInvalid={!!form.errors.country && form.touched.country}>
          <FormLabel>
            <FormattedMessage
              defaultMessage="Paese"
              id="ui.user.UserProfile.edit.country"
            />
          </FormLabel>
          <InputGroup>
            <InputLeftAddon>
              <BiWorld />
            </InputLeftAddon>
            <Input
              disabled={disabled}
              name="country"
              onBlur={form.handleBlur}
              onChange={form.handleChange}
              value={form.values.country ?? ''}
            />
          </InputGroup>
        </FormControl>

        <FormControl isInvalid={!!form.errors.city && form.touched.city}>
          <FormLabel>
            <FormattedMessage
              defaultMessage="Città"
              id="ui.user.UserProfile.edit.city"
            />
          </FormLabel>
          <CitySelector
            onChange={(value) => {
              form.setFieldValue('city', value, true);
              form.setFieldTouched('city', true, true);
            }}
            value={form.values.city ?? ''}
          />
        </FormControl>
      </HStack>

      <ProfileDataUpdateActions disabled={disabled} form={form} />
    </>
  );
};
