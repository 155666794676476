import { useAuth } from 'app/AuthProvider';
import { useEffect, useState } from 'react';
import { ThesisReactions } from 'types/catalog/Reactions';

const Insert = 'insert';
const Delete = 'delete';

type Insert = typeof Insert;
type Delete = typeof Delete;

type Method = Insert | Delete;

const mapmethodToSign = (method: Method): number =>
  method === Insert ? 1 : -1;

export const useReactionActions = (entryid: string) => {
  const [reactions, setReactions] = useState<ThesisReactions | null>();
  const [userReactions, setUserReactions] = useState<string[]>([]);
  const uuid = useAuth().user?.uuid;
  let uuidqs = uuid ? `&userID=${uuid}` : '';

  useEffect(() => {
    fetch(`/v1/reactions/thesis?thesisID=${entryid}${uuidqs}`)
      .then((res) => res.json())
      .then(({ data }) => {
        setReactions(data);
        setUserReactions(data?.userReacts.map((r: any) => r.id_reaction) ?? []);
      });
  }, [entryid]);

  const actionfactory = (method: Method) => (reactionID: string) => {
    const initialReactionsState = reactions;
    const initialUserReactionsState = userReactions;
    const sign = mapmethodToSign(method);

    const reactionsCopy = [...reactions?.reactions!];
    const reactionIdx = reactionsCopy.findIndex!((r) => r.id === reactionID);
    reactionsCopy[reactionIdx] = {
      ...reactionsCopy[reactionIdx],
      number: reactionsCopy[reactionIdx].number + sign,
    };
    const updatedReactionsState = {
      ...initialReactionsState,
      reactions: reactionsCopy,
      total: initialReactionsState?.total! + sign,
    };

    setReactions(updatedReactionsState as unknown as ThesisReactions);

    switch (method) {
      case Insert:
        setUserReactions([
          ...(new Set([...userReactions, reactionID]) as unknown as string[]),
        ]);
        break;
      case Delete:
        setUserReactions(
          initialUserReactionsState.filter((id) => id !== reactionID),
        );
        break;
    }

    fetch(`/v1/reactions/thesis/${method}`, {
      method: 'POST',
      body: JSON.stringify({
        thesisID: entryid,
        reactionID,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then()
      .catch(() => {
        setReactions(initialReactionsState);
        setUserReactions(initialUserReactionsState);
      });
  };

  return {
    insert: actionfactory(Insert),
    delete: actionfactory(Delete),
    reactions,
    userReactions,
  };
};
