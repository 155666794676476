import { Text } from '@chakra-ui/layout';
import Link from 'next/link';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Activity, ActivityProps } from './Activity';

export interface PublishedThesisProps extends ActivityProps {}

export const PublishedThesis: FC<PublishedThesisProps> = ({ activity }) => {
  return (
    <Activity activity={activity}>
      <FormattedMessage
        defaultMessage="Ha pubblicato la tesi {0}"
        id="pages.laura.u.PublishedThesis"
        values={{
          0: (
            <Link href={`/thesis/${activity.slug}`} passHref>
              <Text as="a" fontSize="sm" color="blue.500">
                {activity.title}
              </Text>
            </Link>
          ),
        }}
      />
    </Activity>
  );
};
