import { useTheme } from '@chakra-ui/react';
import React, { FC } from 'react';
import { IconBaseProps } from 'react-icons';
import { FaFacebookSquare, FaLinkedin, FaYoutube } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import { fallback, isobject, multi } from 'tiinvo';
import * as TSocial from 'types/Social';
import { Theme } from '../themes';

export interface IconProps extends IconBaseProps {
  displayColor?: boolean;
  social: TSocial.Social;
}

const maptocolor = multi(
  fallback(`inherit`),
  [TSocial.isfacebook, `facebook`],
  [TSocial.isgoogle, `google`],
  [TSocial.islinkedin, `linkedin`],
  [TSocial.isyoutube, `youtube`],
);

const maptoicon = multi(
  fallback((<></>) as any),
  [TSocial.isfacebook, fallback(FaFacebookSquare)],
  [TSocial.isgoogle, fallback(FcGoogle)],
  [TSocial.islinkedin, fallback(FaLinkedin)],
  [TSocial.isyoutube, fallback(FaYoutube)],
);

export const Icon: FC<IconProps> = ({ displayColor, social, ...props }) => {
  const Component = maptoicon(social);
  const fillkey = maptocolor(social);
  const colours = useTheme<Theme>().colors as unknown as Record<string, string>;
  const fill = displayColor
    ? isobject(colours[fillkey])
      ? colours[fillkey][500]
      : colours[fillkey]
    : undefined;

  return <Component fill={fill} {...props} />;
};
