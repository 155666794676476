import { Heading, HeadingProps } from '@chakra-ui/react';
import React, { FC } from 'react';

export interface TitleProps extends Omit<HeadingProps, 'variant'> {}

export const Title: FC<TitleProps> = (props) => {
  return (
    <Heading
      marginBottom="0"
      variant="h1"
      textColor="white"
      textShadow="outline"
      {...props}
    />
  );
};
