import { createContext, createElement, Dispatch, FC, useContext } from 'react';

export type UserData = {
  email: string;
  type: string;
  username: string;
  uuid: string;
};

export type AuthContextProps = {
  jwt: string | null;
  user: UserData | null;
  isLoggedIn: boolean;
  setJwt: Dispatch<string | null>;
  setUser: Dispatch<UserData | null>;
  setIsLoggedIn: Dispatch<Boolean>;
};

export const AuthContext = createContext<AuthContextProps>({
  jwt: null,
  user: null,
  isLoggedIn: false,
  setJwt: () => null,
  setUser: () => null,
  setIsLoggedIn: () => null,
});

export const AuthProvider: FC<AuthContextProps> = ({ children, ...props }) => {
  return createElement(AuthContext.Provider, { value: props }, children);
};

export const useAuth = () => useContext(AuthContext);
